import React from 'react';
import { createSession } from 'opentok-react';
import CONFIG_VARS from '../../shared/constants/CONFIG_VARS';

function withCallSession(WrappedComponent){
    return class withCallSession extends React.Component{
        constructor(props) {
            super(props);
            this.displayName = 'withCallSession';
            this.state = {
                error: null,
                streams: [],
                audio: true,
                video: true,
                audioSource: null,
                videoSource: null,
                speaker: null,

                facingMode: "user",
                screenShare: false,
                focusedStream: null,
                focusedVideoElement: null,
                mutedParticipants: []
            };

            this.sessionHelper = createSession({
                apiKey: this.props.apiKey,
                sessionId: this.props.sessionId,
                token: this.props.token,
                onError: this.onError,
                onStreamsUpdated: streams => {
                    if(streams.length === 0 && typeof this.props.onNoParticipants !== 'undefined'){
                        this.props.onNoParticipants();
                        this.setState({
                            mutedParticipants: []
                        });
                    }

                    let focusedStream = streams.length > 0 ? streams[0] : null;

                    let screenStream = streams.find((stream) => stream.videoType === 'screen');
                    if (screenStream) {
                        focusedStream = screenStream;
                    }

                    if (this._is_mounted) {
                        this.setState({
                            streams: streams,
                            focusedStream: focusedStream,
                            screenShare: screenStream ? false : this.state.screenShare
                        });
                    } else {
                        this.state.streams = streams;
                        this.state.focusedStream = focusedStream;
                        this.state.screenShare = screenStream ? false : this.state.screenShare;
                    }
                }
            });
        }
        componentDidMount() {
            this._is_mounted = true;
        }
        componentWillUnmount() {
            this.sessionHelper.disconnect();
        }
        onError = (err) => {
            this.setState({ error: `Failed to connect: ${err.message}` });
        }
        setCallState = (state) => {
            this.setState(state);
        }
        handleFocus = (stream) => {
            this.setState({focusedStream: stream});
        }
        handleAudio = () => {
            this.setState({audio: !this.state.audio});
        }
        handleVideo = () => {
            this.setState({video: !this.state.video});
        }
        handleEnableAudioVideo = () => {
            this.setState({audio: true, video: true });
        }
        handleScreenShare = () => {
            this.setState({screenShare: !this.state.screenShare});
        }
        handleFlipCamera = () => {
            this.setState({
                facingMode: this.state.facingMode === "user" ? "environment" : "user",
            });
        }
        handleFocusedVideoCreated = (videoElement) => {
            this.setState({focusedVideoElement: videoElement});
        }
        handleChangeParticipantAudio = (stream) => (isMuted) => {
            if(stream.name === CONFIG_VARS.settingsModalStream ) return;

            let mutedParticipants = [...this.state.mutedParticipants];
            if(isMuted && !mutedParticipants.find((e) => e.id === stream.id)){ //add into muted participant
                mutedParticipants.push({id: stream.id, name: stream.name});
            }else{ //remove from muted participant
                mutedParticipants = mutedParticipants.filter((e) => e.id !== stream.id);
            }
            this.setState({mutedParticipants: mutedParticipants});
        }
        render(){
            return(
                <WrappedComponent
                    {...this.props}
                    callSession={{
                        session: this.sessionHelper.session,
                        error: this.state.error,
                        streams: this.state.streams,

                        audio: this.state.audio,
                        video: this.state.video,
                        audioSource: this.state.audioSource,
                        videoSource: this.state.videoSource,
                        facingMode: this.state.facingMode,
                        screenShare: this.state.screenShare,
                        speaker: this.state.speaker,

                        focusedStream: this.state.focusedStream,
                        focusedVideoElement: this.state.focusedVideoElement,
                        mutedParticipants: this.state.mutedParticipants,

                        setState: this.setCallState,
                        handleFocus: this.handleFocus,
                        handleAudio: this.handleAudio,
                        handleVideo: this.handleVideo,
                        handleEnableAudioVideo: this.handleEnableAudioVideo,
                        handleScreenShare: this.handleScreenShare,
                        handleFlipCamera: this.handleFlipCamera,
                        handleFocusedVideoCreated: this.handleFocusedVideoCreated,
                        handleChangeParticipantAudio: this.handleChangeParticipantAudio,
                    }}
				/>
            );
        }
    };
}
export default withCallSession;
