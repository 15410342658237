function KeyChar(event){

    this.getKeyChar = (event) => {
        let keyCode = event.code || event.keyCode; 
        let keyChar = null;

        switch(keyCode){
            case 27:
                keyChar = "ESC";
            break;
            case 40:
                keyChar = "ARROW_DOWN";
            break;
            case 38:
                keyChar = "ARROW_UP";
            break;
            case 13:
                keyChar = "ENTER";
            break;
            default:
                keyChar = null;
            break;
        }

        return keyChar;
    }
    this.is = (keyChar) => {
        return this.getKeyChar(event) === keyChar;
    }
    
    return this;
}
export default ((event) =>( new KeyChar(event)));