export default [
	{
		code: 'first_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'First name cannot be empty',
				rule: ['required'],
			}
		}
	},
	{
		code: 'last_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Last name cannot be empty',
				rule: ['required'],
			},
		}
	},
	{
		code: 'patient_consent',
		element_type: 'input',
		rules: {
			isTrue: {
				type: 'error',
				error_type: 'hard',
				message: 'Your consent is required.',
				rule: ['isTrue'],
			},
		}
	}
];
