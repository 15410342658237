import React from 'react';
import ModalCard from "../shared/Modal/ModalCard";
import withClinicalRoom from '../providers/Room/withClinicalRoom';
import consumeApp from '../providers/consumeApp';
import Checkbox from '../shared/Checkbox';
import Input from '../shared/Input';
import Select from '../shared/Select';
import './dashboard.scss';
import LK_INVITE_OPTIONS from '../shared/constants/LK_INVITE_OPTIONS';
import HttpClient from '../shared/utils/HttpClient';
import withValidation from '../validations/withValidation';
import DashboardFormRules from '../validations/FormRules/DashboardFormRules';
import MessageInline from '../shared/MessageInline';
import { NavLink } from 'react-router-dom';
import Avatar from '../shared/Avatar';
import InviteEmailTemplate from '../templates/InviteEmailTemplate';
import InviteSMSTemplate from '../templates/InviteSMSTemplate';

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_email_modal: false,
            open_sms_modal: false,
            email_method: "personal",
            patient_consented_to_sms: false,
            patient_email: "",
            patient_phone_number: "",
            message: null,
            email_message: "",
            text_message: "",
            waiting_room_url: window.location.origin
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.waiting_room !== prevProps.waiting_room) {
            this.setInviteMessages(this.props.authUser, this.props.waiting_room);
        }
    }
    copyWaiting_room_url = () => {
        var copyText = document.getElementById("waiting_room_url");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });
    }
    handleCheckBoxChange = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    handleInviteChange = (name, value) => {
        this.setState({
            [value]: true
        });
    }
    OnEmailClose = () => {
        this.setState({
            patient_email: "",
            open_email_modal: false
        });
    }
    onEmailRadioChange = (event) => {
        this.setState({
            email_method: event.target.value
        })
    }
    onPersonalEmailConfirm = email_message => () => {
        window.location.href = `mailto:?subject=docOS%20Telehealth%20Invite&body=${email_message}`;
    }
    ondocOSEmailConfirm = email_message => (event) => {
        event.preventDefault();

        if (!this.props.validate(this.state)) return;

        this.setState({
            loading: true,
            message: null
        });

        let apiName = 'telehealthApi';
        let path = '/providers/send_email_invite';

        let postData = {
            reply_to: this.props.authUser.attributes['email'],
            to: this.state.patient_email,
            subject: 'docOS Telehealth Invite',
            body: email_message.replaceAll('%0d%0a', "\n")
        };

        HttpClient().post(apiName, path, postData)
            .then(() => {
                this.setState({
                    open_email_modal: false,
                    loading: false,
                    message: {
                        type: "success",
                        text: "Email invitation was sent successfully to the patient.",
                    }
                });
            }).catch((error) => {
                this.setState({
                    open_email_modal: false,
                    loading: false,
                    message: {
                        type: "error",
                        text: error.response.data.detail
                    }
                });
            });
    }
    handlePhoneNumberChange = (event) => {
        if (event.target.value.substring(0, 2) !== '+1') {
            event.target.value = '+1' + event.target.value;
        }
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation on first input.
        this.props.validate({
            [`${event.target.name}`]: event.target.value
        });
    }
    OnSMSClose = () => {
        this.setState({
            patient_phone_number: "",
            patient_consented_to_sms: false,
            open_sms_modal: false
        });
    }
    OnSMSConfirm = text_message => (event) => {
        event.preventDefault();

        if (!this.props.validate(this.state)) return;

        this.setState({
            loading: true,
            message: null
        });

        let apiName = 'telehealthApi';
        let path = '/providers/send_sms_invite';

        let postData = {
            patient_phone_number: this.state.patient_phone_number,
            message: text_message
        };

        HttpClient().post(apiName, path, postData)
        .then(() => {
            this.setState({
                patient_phone_number: "",
                patient_consented_to_sms: false,
                open_sms_modal: false,
                loading: false,
                message: {
                    type: "success",
                    text: "Text message invite was sent successfully to the patient.",
                }
            });
        }).catch((error) => {
            this.setState({
                open_sms_modal: false,
                loading: false,
                message: {
                    type: "error",
                    text: error.response.data.detail
                }
            });
        });
    }
    setInviteMessages = (authUser, waiting_room) => {
        if (waiting_room) {
            let waiting_room_url = window.location.origin + '/' + waiting_room.slug;
            let email_message = InviteEmailTemplate({given_name: authUser.attributes.given_name, family_name: authUser.attributes.family_name, waiting_room_url: waiting_room_url});
            let text_message = InviteSMSTemplate({given_name: authUser.attributes.given_name, family_name: authUser.attributes.family_name, waiting_room_url: waiting_room_url});

            this.setState({
                email_message: email_message,
                text_message: text_message,
                waiting_room_url: waiting_room_url
            });
        }
    }

    render() {
        let waiting_room_url = this.state.waiting_room_url;

        return (
            <div className="py-5 px-5">
                <div className='columns is-vcentered is-centered'>
                    <div className='column is-one-thirds'>
                        <div className='box'>
                            <div className="is-clearfix" />

                            <h4 className="title is-4">Welcome, {this.props.authUser.fullName}!</h4>
                            <br />
                            <p>To invite someone to your waiting room, share this link:</p>
                            <br />


                            <div className="field is-grouped is-hidden-mobile">
                                <div className="control is-expanded">
                                    <input
                                        id="waiting_room_url"
                                        className="input invite-url-input"
                                        readOnly
                                        value={waiting_room_url}
                                        size={waiting_room_url.length}
                                    />
                                </div>
                                <div className="control">
                                    <button className="button is-primary" onClick={this.copyWaiting_room_url}>
                                        Copy
                                    </button>
                                </div>

                                <Select
                                    placeholder={"Invite via"}
                                    name="invite-via"
                                    options={LK_INVITE_OPTIONS}
                                    className="is-fullwidth"
                                    value=""
                                    onChange={this.handleInviteChange}
                                />
                            </div>

                            <div className="field is-horizontal is-hidden-tablet">
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <input
                                                id="waiting_room_url"
                                                className="input invite-url-input"
                                                readOnly
                                                value={waiting_room_url}
                                                size={waiting_room_url.length}
                                            />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <button className="button is-primary is-fullwidth" onClick={this.copyWaiting_room_url}>
                                                Copy
                                            </button>
                                        </div>
                                    </div>

                                    <Select
                                        placeholder={"Invite via"}
                                        name="invite-via"
                                        options={LK_INVITE_OPTIONS}
                                        className="is-fullwidth"
                                        value=""
                                        onChange={this.handleInviteChange}
                                    />
                                </div>
                            </div>

                            {this.state.message &&
                                <MessageInline {...this.state.message} />
                            }

                        </div>
                        <div><h5 className="title is-5">Patient Queue</h5></div>
                        <br />
                        {this.props.sessions.map((session) =>
                            <div key={session.id} className='box patient-queue'>
                                <ul className="menu-list queue">
                                    <li>
                                        <NavLink to={"/session/" + session.id} >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="pr-3">
                                                    <Avatar
                                                        first_name={session.patient.first_name}
                                                        last_name={session.patient.last_name}
                                                        avatarColor={session.avatarColor}
                                                    />
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="level">
                                                        <div className="level-left">
                                                            <div className="level-item">
                                                                <strong>{session.patient.first_name} {session.patient.last_name}</strong>&nbsp;&nbsp;
                                                            </div>
                                                            <div className="level-item">Room: {session.waiting_room.slug}&nbsp;&nbsp;</div>
                                                        </div>
                                                        <div className="level-right">
                                                            <div className="level-item">
                                                                <button className="button is-primary" >
                                                                    <i className="fa fa-phone-alt" /> &nbsp;&nbsp;Start Call
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <ModalCard
                        icon="fa-envelope"
                        name="email"
                        title="Invite with email"
                        isOpen={this.state.open_email_modal}
                        onClose={this.OnEmailClose}
                        onConfirm={this.state.email_method === "personal" ? this.onPersonalEmailConfirm(this.state.email_message) : this.ondocOSEmailConfirm(this.state.email_message)}
                    >
                        <div className="control">
                            <label className="radio">
                                <input type="radio" name="email-method" value="personal" checked={this.state.email_method === "personal"} onChange={this.onEmailRadioChange} />&nbsp;Use my personal email client
                            </label>
                            <label className="radio">
                                <input type="radio" name="email-method" value="docOS" checked={this.state.email_method === "docOS"} onChange={this.onEmailRadioChange} />&nbsp;Send from docOS
                            </label>
                        </div>

                        {this.state.open_email_modal && this.state.email_method === "docOS" &&
                            <div className='control'>
                                <br></br>
                                <Input
                                    label='Patient Email'
                                    name='patient_email'
                                    value={this.state.patient_email}
                                    onChange={this.handleChange}
                                    validation={this.props.validations.patient_email}
                                />
                                <Input
                                    label='Waiting Room'
                                    type='waiting_room_url'
                                    name='waiting_room_url'
                                    value={waiting_room_url}
                                    onChange={this.handleChange}
                                />

                                <div className="field">
                                    <label className="label">Message</label>
                                    <div className="control">
                                        <textarea name="email_message" className="textarea is-small" rows="10" onChange={this.handleChange} defaultValue={this.state.email_message.replaceAll('%0d%0a', "\n")}></textarea>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalCard>

                    <ModalCard
                        icon="fa-comment"
                        name="text"
                        title="Invite with text messaging"
                        isOpen={this.state.open_sms_modal}
                        onClose={this.OnSMSClose}
                        onConfirm={this.OnSMSConfirm(this.state.text_message)}
                    >
                        <Input
                            label='Patient Phone Number'
                            name='patient_phone_number'
                            value={this.state.patient_phone_number}
                            onChange={this.handlePhoneNumberChange}
                            placeholder="(312) 465-7900"
                            validation={this.props.validations.patient_phone_number}
                        />
                        <Input
                            label='Waiting Room'
                            type='waiting_room_url'
                            name='waiting_room_url'
                            value={waiting_room_url}
                            onChange={this.handleChange}
                        />

                        <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
                                <textarea name='text_message' className="textarea is-small" rows="2" defaultValue={this.state.text_message} onChange={this.handleChange}></textarea>
                            </div>
                        </div>

                        <div className="level-item is-pulled-left">
                            <Checkbox
                                name="patient_consented_to_sms"
                                checked={this.state.patient_consented_to_sms}
                                value={this.state.patient_consented_to_sms}
                                onChange={this.handleCheckBoxChange}
                                validation={this.props.validations.patient_consented_to_sms}
                                label="Patient consented to receiving text messages."
                            />
                        </div>
                    </ModalCard>
                </div>
            </div >
        );
    };
}
export default consumeApp(withClinicalRoom(withValidation(DashboardPage, DashboardFormRules)));
