import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    classNameValidation() {
        if (!this.props.validation) return "";

        switch (this.props.validation.type) {
            case "success":
                return "is-success ";
            case "error":
            default:
                return "is-danger";
        }
    }
    handleChange = (event) => {
        this.props.onChange(this.props.name, !this.props.checked, this.props.value);
    }
    render() {
        return (
            <div>
                <label className={"checkbox " + this.props.className}>
                    <input
                        type="checkbox"
                        className={this.props.className}
                        onChange={this.handleChange}
                        checked={this.props.checked}
                        value={this.props.value}
                        name={this.props.name}
                    />
                &nbsp;{this.props.label}&nbsp;
                </label>

                {
                    this.props.validation &&
                    <p className={"help " + this.classNameValidation()}>
                        &nbsp;{this.props.validation.message}
                    </p>
                }
            </div>
        );
    }
}
Checkbox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onChange: PropTypes.func,
};
export default Checkbox;
