import React from 'react';
import ListWaitingRooms from './ListWaitingRooms';
import FormWaitingRooms from './FormWaitingRooms';
import MyRoute from '../../core/router/MyRoute';
import HttpClient from '../../shared/utils/HttpClient';

class WaitingRoomsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            waiting_rooms: []
        };
    }
    componentDidMount(){
        this.getWaitingRooms();        
    }
    getWaitingRooms(){
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms'; 

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                waiting_rooms: data
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    newWaitingRoom = (waiting_room) => {
        this.setState({
            waiting_rooms: [waiting_room, ...this.state.waiting_rooms]
        });
    }
    updateWaitingRoom = (waiting_room_id, data) => {
        waiting_room_id = parseInt(waiting_room_id);
        this.setState({
            waiting_rooms: this.state.waiting_rooms.map((item) => {
                return item.id === waiting_room_id ? { ...item, ...data} : item;
            })
        });
    }
    handleRemove = (waiting_room) => () => {
        let isConfirmed = window.confirm("Are you sure you want to remove?");
        if( isConfirmed ){
            let apiName = 'telehealthApi';
            let path = '/waiting_rooms/'+waiting_room.id; 
            this.setState({
                loading: true
            });
            HttpClient().delete(apiName, path)
            .then( (data) => {
                this.setState({
                    loading: false,
                    waiting_rooms: this.state.waiting_rooms.filter((item) => item.id !== waiting_room.id)
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
        }
    }
    render(){
        return (
            <div className="py-5 px-5">
                <div className='columns is-vcentered is-centered'>
                    <div className='column is-12'>
                        <div className='box'>
                            <ListWaitingRooms 
                                waiting_rooms={this.state.waiting_rooms} 
                                loading={this.state.loading}
                                onRemove={this.handleRemove}
                            />
                            <MyRoute 
                                exact 
                                path={"/waiting_rooms/new"} 
                                title={"New waiting room"} 
                                mode="new" 
                                onCreate={this.newWaitingRoom}
                                component={FormWaitingRooms} 
                            />
                            <MyRoute 
                                exact 
                                path={"/waiting_rooms/edit/:id"} 
                                title={"Edit waiting room"} 
                                mode="edit" 
                                onUpdate={this.updateWaitingRoom}
                                component={FormWaitingRooms} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WaitingRoomsPage;