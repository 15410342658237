import React from 'react';
import Modal from '../../shared/Modal/Modal';
import Select from '../../shared/Select';
import CONFIG_VARS from '../../shared/constants/CONFIG_VARS';
import MiniPublisher from './MiniPublisher';
import MicrophoneVolume from './controls/MicrophoneVolume';
import './SettingsModal.scss';

class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            cameraDevices: [],
            microphoneDevices: [],
            speakerDevices : [],
        };
    }
    getDevices = () => {

        const callSession = this.props.callSession;

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.warn("enumerateDevices() not supported.");
            return;
        }

        navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
            let cameraDevices = [];
            let microphoneDevices = [];
            let speakerDevices = [];
            devices.forEach((device) => {
                if(device.kind.toLowerCase() === "videoinput"){
                    // For Mobile should be only front/back camera
                    cameraDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                }else if(device.kind.toLowerCase() === "audioinput"){
                    microphoneDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                }else if(device.kind.toLowerCase() === "audiooutput"){
                    speakerDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                }
            });

            let videoSource = cameraDevices.length === 1 ? cameraDevices[0] : null;
            let audioSource = microphoneDevices.length === 1 ? microphoneDevices[0] : null;

            this.setState({
                cameraDevices: cameraDevices,
                microphoneDevices: microphoneDevices,
                speakerDevices: speakerDevices,
            });

            callSession.setState({
                videoSource: videoSource,
                audioSource: audioSource,
            });
        })
        .catch((err) => {
            console.warn(err.name + ": " + err.message);
        });
    }
    componentDidUpdate(prevProps){
        if(this.props.isOpen && this.props.isOpen !== prevProps.isOpen){
            this.getDevices();
        }
    }
    handleChange = (name, value) => {
        const callSession = this.props.callSession;
        callSession.setState({
            [name]: value
        });
    }
    isSpeakerSelectDisabled = () => {
        return this.state.speakerDevices.length < 1;
    }
    render(){

        const callSession = this.props.callSession;
        const defaultSpeakers = [{value: "default", label: "Default"}];

        return(
            <Modal isModalFullScreen transparent isOpen={this.props.isOpen}>
                <div className="box">
                    <h2 className="header">
                        Call Settings
                        <div className="btn-close" onClick={this.props.onClose} >
                            <span className="ic-times" />
                        </div>
                    </h2>

                    <div className="video-test" >
                        <MiniPublisher
                            session={callSession.session}
                            useAvatar={false}
                            properties={{
                                name: CONFIG_VARS.settingsModalStream,
                                publishAudio: false,
                                publishVideo: callSession.video,
                                facingMode: callSession.facingMode
                            }}
                        />
                        <div className="mic-test">
                            <MicrophoneVolume sm isOn={callSession.audio} />
                        </div>
                    </div>

                    <div className="my-4">
                        <div className="columns is-centered" >
                            <div className="column" >
                                <Select
                                    placeholder={"Default"}
                                    name="videoSource"
                                    label="Camera"
                                    options={this.state.cameraDevices}
                                    className="is-fullwidth"
                                    value={callSession.videoSource}
                                    onChange={this.handleChange}
                                />
                                <Select
                                    placeholder={"Default"}
                                    name="audioSource"
                                    label="Microphone"
                                    options={this.state.microphoneDevices}
                                    className="is-fullwidth"
                                    value={callSession.audioSource}
                                    onChange={this.handleChange}
                                />
                                <Select
                                    placeholder={"Default"}
                                    name="speaker"
                                    label="Speaker"
                                    disabled={this.isSpeakerSelectDisabled()}
                                    options={this.isSpeakerSelectDisabled() ? defaultSpeakers : this.state.speakerDevices}
                                    className="is-fullwidth"
                                    value={callSession.speaker ? callSession.speaker : 'default'}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
export default SettingsModal;
