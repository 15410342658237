import Amplify from "aws-amplify";
import OneloginAuth from './OneloginAuth';
import awsconfigDevelopment from "./config/aws-config";
import awsconfigLocal from "./config/aws-config.local";
import awsconfigUat from "./config/aws-config.uat";
import awsconfigProduction from "./config/aws-config.production";

const ConfigFactory = function(){
    this.env = null;
    this.init = (env = null) => {
        return Amplify.configure(this.getConfig(env));
    };
    this.getEnv = () => {
        return this.env ? this.env : 
                process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";
    };
    this.setEnv = (env) => {
        this.env = env;
    };
    this.getConfig = (env = null) => {
        
        env = env ? env : this.getEnv();

        let awsconfig = awsconfigDevelopment;
        switch(env){
            case "production":
                awsconfig = awsconfigProduction;
            break;

            case "uat":
                awsconfig = awsconfigUat;
            break;

            case "local":
                awsconfig = awsconfigLocal;
            break;

            case "development":
            default:
                awsconfig = awsconfigDevelopment;
            break;
        }
        
        awsconfig = this.setAccessTokenForEndpoints(awsconfig);

        return awsconfig;
    };
    this.getEndpoint = (name) => {
        return this.getConfig().aws_cloud_logic_custom.find((endpoint) => endpoint.name === name);
    };
    this.setAccessTokenForEndpoints = (awsconfig) => {
        return {
            ...awsconfig,
            aws_cloud_logic_custom: awsconfig.aws_cloud_logic_custom.map((endpoint) => ({
                ...endpoint,
                custom_header: async () => {
                    let customHeaders = {};

                    try {
                        let accessToken = (await this.getAccessToken('cognito'));
                        if(accessToken){
                            customHeaders =  {
                                Authorization: `Bearer ${accessToken}`
                            };
                        }

                    } catch(error) {
                        customHeaders = {};
                    }

                    return customHeaders;
                }
            }))
        };
    };
    this.getAccessToken = async (type) => {
        switch(type){
            case "onelogin":
                return (await OneloginAuth.getIdToken());
            default:
            case "cognito":
                return (await Amplify.Auth.currentSession()).getAccessToken().getJwtToken()
            
        }
    }
}
export default (new ConfigFactory());