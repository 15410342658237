import React from 'react';
import PropTypes from 'prop-types';
import './ControlBtn.scss';

const VideoBtn = (props) => {
    return (
        <div id="ctrl-btn-video" className="call-control" onClick={props.onClick}>
            <div className="ctr-btn" >
                <span className={`ic-video-${props.isOn ? 'on' : 'off'}`} />
            </div>
            <div className="ctr-label">
                {props.isOn ? 'Stop video' : 'Start video'}
            </div>
        </div>
    );
}
VideoBtn.propTypes = {
    onClick: PropTypes.func,
    isOn: PropTypes.bool
};
export default VideoBtn;