import React from 'react';
import ListProviders from './ListProviders';
import FormProviders from './FormProviders';
import MyRoute from '../../core/router/MyRoute';
import HttpClient from '../../shared/utils/HttpClient';

class ProvidersPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            providers: []
        };
    }
    componentDidMount(){
        this.getProviders();
    }
    getProviders(){
        let apiName = 'telehealthApi';
        let path = '/providers'; 

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                providers: data
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    newProvider = (provider) => {
        this.setState({
            providers: [provider, ...this.state.providers]
        });
    }
    updateProvider = (provider_id, data) => {
        provider_id = parseInt(provider_id);
        this.setState({
            providers: this.state.providers.map((item) => {
                return item.id === provider_id ? { ...item, ...data} : item;
            })
        });
    }
    handleRemove = (provider) => () => {
        let isConfirmed = window.confirm("Are you sure you want to remove?");
        if( isConfirmed ){
            let apiName = 'telehealthApi';
            let path = '/providers/'+provider.id; 
            this.setState({
                loading: true
            });
            HttpClient().delete(apiName, path)
            .then( (data) => {
                this.setState({
                    loading: false,
                    providers: this.state.providers.filter((item) => item.id !== provider.id)
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
        }
    }
    render(){
        
        return (
            <div className="py-5 px-5">
                <div className='columns is-vcentered is-centered'>
                    <div className='column is-12'>
                        <div className='box'>
                            <ListProviders 
                                providers={this.state.providers} 
                                loading={this.state.loading}
                                onRemove={this.handleRemove}
                            />
                            <MyRoute 
                                exact 
                                path={"/providers/new"} 
                                title={"New provider"} 
                                mode="new" 
                                onCreate={this.newProvider}
                                component={FormProviders} 
                            />
                            <MyRoute 
                                exact 
                                path={"/providers/edit/:id"} 
                                title={"Edit provider"} 
                                mode="edit" 
                                onUpdate={this.updateProvider}
                                component={FormProviders} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProvidersPage;