import React from 'react';
import { withRouter } from 'react-router-dom';
import Countly from 'countly-sdk-web';
import Modal from "../shared/Modal/Modal";
import './EndCallPage.scss';
import {countlyAddEvent} from '../countly';

class EndCallPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalFullScreen: true,
            isOpenModal: true
        };
    }
    componentDidMount(){
        Countly.track_pageview("endCall");
    }
    handleRejoin = () => {
        //ATTENTION: Maybe countly sending request will fail because of the window reload
        countlyAddEvent("clickRejoin"); 
        window.location.reload()
    }
    render() {
        return (
            <Modal isModalFullScreen={this.state.isModalFullScreen} isOpen={this.state.isOpenModal}>
                <div className="box">
                    <figure className="image container is-96x96">
                        <img src="../../ic-how-are-you.png" width="68" height="68" alt="village medical end call" />
                    </figure>
                    <div className="my-4" >
                        <div className="columns" >
                            <div className="column" >
                                <h3 className="has-text-centered has-text-weight-bold is-size-3">Thank you</h3>
                                <h4 className="has-text-centered is-size-4" >
                                    Your appointment with {this.props.provider.first_name} has finished</h4>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <p className="has-text-centered has-text-weight-bold is-size-5">
                        Call Dropped? </p>
                    <div className="column has-text-centered has-text-weight-bold">
                        <a href="#rejoin" className="is-size-6" onClick={this.handleRejoin}>
                            Rejoin the waiting room
                        </a>
                    </div>
                </div>
            </Modal>
        );
    };
}
export default withRouter(EndCallPage);
