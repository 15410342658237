import React from 'react';
import Select2 from '../../shared/Select2';
import HttpClient from '../../shared/utils/HttpClient';
import Loading from '../../shared/Loading';
import ProviderAssigned from './ProviderAssigned';

class ProvidersWaitingRoom extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            assigned_providers: [],
            unassigned_providers: []
        };
    }
    componentDidMount(){
        this.getWaitingRoomProviders(this.props.waiting_room_id);
    }
    getWaitingRoomProviders(waiting_room_id){
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/providers`; 
        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                assigned_providers: data
            });
            this.getUnassignedProviders(data);
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    getUnassignedProviders(assigedProviders){
        let listAssigedProvidersIds = assigedProviders.map((item) => item.id);
        let apiName = 'telehealthApi';
        let path = '/providers'; 
        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                unassigned_providers: data.filter((item) => listAssigedProvidersIds.indexOf(item.id) < 0 )
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    handleChangeSelect = (name, option) => {
        this.assignProvider(this.props.waiting_room_id, option.value, () => {
            this.setState({
                assigned_providers: [ option.item, ...this.state.assigned_providers],
                unassigned_providers: this.state.unassigned_providers.filter((item) => item.id !== option.value)
            });
        });
    }
    handleUnassign = (provider) => {
        this.setState({
            assigned_providers: this.state.assigned_providers.filter((item) => item.id !== provider.id),
            unassigned_providers: [ provider, ...this.state.unassigned_providers]
        });
    }
    getUnassignedProviderOptions(){
        return this.state.unassigned_providers.map((provider) => {
            return {
                value: provider.id,
                label: provider.first_name + ' ' + provider.last_name,
                item: provider,
            };
        });
    }
    assignProvider(waiting_room_id, provider_id, onSuccess = null){
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/assign_provider`; 
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false
            });
            if(onSuccess) onSuccess();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    render(){
        
        let unassignedProviderOptions = this.getUnassignedProviderOptions();

        return(
            <div className="panel">
                <div className="panel-heading">
                    Assign providers
                </div>
                {unassignedProviderOptions.length > 0 &&
                    <div className="panel-block" >
                        <Select2
                            placeholder="Select providers to assign"
                            name='assign_provider'
                            onChange={this.handleChangeSelect}
                            options={unassignedProviderOptions}
                        />
                    </div>
                }
                {this.state.loading && 
                    <div className="panel-block d-flex justify-content-center" >
                        <Loading />
                    </div>
                }
                {this.state.assigned_providers.map((provider) => 
                    <div className="panel-block d-flex justify-content-between align-items-center" >
                        <ProviderAssigned 
                            waiting_room_id={this.props.waiting_room_id} 
                            provider={provider} 
                            onUnassign={this.handleUnassign} 
                        />
                    </div>
                )}
            </div>
        );
    }
}
export default ProvidersWaitingRoom;