import React from 'react';

const ModalCard = (props) => {
    return (
        <div className={`modal ${props.isOpen ? "is-active" : ""}`}>
            <div className={`modal-background ${props.blur}`} onClick={props.onClose}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    {props.icon && <i className={`fas ${props.icon} icon is-large`}></i>}
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.onClose} ></button>
                </header>
                <section className="modal-card-body">
                    {props.children}
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={props.onClose} >{props.cancelText}</button>
                    <button className="button is-primary" onClick={props.onConfirm} >{props.confirmText}</button>
                </footer>
            </div>
        </div >
    );
};
ModalCard.defaultProps = {
    confirmText: "Confirm",
    cancelText: "Cancel",
};
export default ModalCard;