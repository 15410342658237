import React from 'react';
import PropTypes from 'prop-types';

class Input extends React.Component{
    isValid(){
        if(!this.props.validation) return undefined;

        switch(this.props.validation.type){
            case "success":
                return "true";
            
            case "error":
            default:
                return "false";
        }
    }
    classNameValidation(){
        if(!this.props.validation) return "";

        switch(this.props.validation.type){
            case "success":
                return " is-success ";
            case "error":
            default:
                return " is-danger ";
        }
    }
    classNameLabel(){
        if(!this.props.validation) return "label";

        let labelClass = "";
        switch(this.props.validation.type){
            case "success":
                labelClass = " has-text-success ";
                break;
            case "error":
            default:
                labelClass = " has-text-danger ";
                break;
        }
        
        return "label " + labelClass;
    }
    render(){
        
        let divClassName = "field "+(this.props.divClassName ? this.props.divClassName : "");
        let inputClassName = "input "+(this.props.className ? this.props.className : "");

        return(
            <div className={divClassName} >
                {this.props.label &&
                    <label htmlFor={this.props.name} className={this.classNameLabel()} >
                        {this.props.label}
                    </label>
                }
                <input 
                    id={this.props.id ? this.props.id : this.props.name}
                    className={inputClassName+this.classNameValidation()} 
                    type={this.props.type} 
                    name={this.props.name}
                    value={this.props.value}
                    autoComplete={this.props.autoComplete}
                    autoCorrect={this.props.autoCorrect}
                    autoFocus={this.props.autoFocus}
                    onChange={this.props.onChange} 
                    onFocus={this.props.onFocus}
                    onKeyDown={this.props.onKeyDown}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder ? this.props.placeholder : ""} 
                    valid={this.isValid()}
                />
                {this.props.validation &&
                    <p className={"help"+this.classNameValidation()}>
                        {this.props.validation.message}
                    </p>
                }
            </div>
        );
    }
}
Input.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
};
Input.defaultProps={
    type:"text",
    value: "",
}
export default Input;