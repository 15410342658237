import React from 'react';
import MessagesContext from './MessagesContext';
var uuid4 = require('uuid4');

class MessagesProvider extends React.Component {

    state = {
        messages: []
    };

    render() {
        return (
            <MessagesContext.Provider
                value={{
                    messages: this.state.messages,
                    showMessage: (msg) => {
                        this.setState((prevState) => {
                            return {
                                messages: [...prevState.messages, {
                                    ...msg,
                                    uuid: uuid4(),
                                }],
                            }
                        });
                    },
                    dismiss: (uuid) => {
                        this.setState((prevState) => {
                            return {
                                messages: prevState.messages.filter((msg)=> msg.uuid !== uuid),
                            };
                        });
                    },
                    dismissByTag: (tag) => {
                        this.setState((prevState) => {
                            return {
                                messages: prevState.messages.filter((msg)=> msg.tag !== tag),
                            };
                        });
                    }
                }}
            >
                {this.props.children}
            </MessagesContext.Provider>
        );
    }
}
export default MessagesProvider;