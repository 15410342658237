import React from 'react';
import './side_collapser_float.scss';

const SideCollapser = (props) => {
    return(
        <div className={`sidebar-collapser-float ${props.isCollapsed ? 'collapsed' : 'expanded' }`} onClick={props.onClick} >
            <i className={`arrow fas ${props.isCollapsed ? 'fa-bars' : 'fa-chevron-left'}`} />
        </div>
    );
}
SideCollapser.defaultProps = {
    isCollapsed: false
};
export default SideCollapser;