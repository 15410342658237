import React from 'react';
import HttpClient from '../shared/utils/HttpClient';
import Loading from '../shared/Loading';
import OTConfig from '../config/OTConfig';
import Websocket from '../shared/Websocket';
import withValidation from '../validations/withValidation';
import PatientFormRules from '../validations/FormRules/PatientFormRules';

import WaitingRoomNotFound from './PatientRoom/WaitingRoomNotFound';
import CheckIn from './PatientRoom/CheckIn';
import FirstTimeCheckIn from './PatientRoom/FirstTimeCheckIn';
import PatientCallContainer from './PatientRoom/PatientCallContainer';
import EndCall from './EndCallPage';
import './PatientRoomPage.scss';
import {countlyAddEvent} from '../countly';

class PatientRoomPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            first_name: "",
            last_name: "",
            patient_consent: false,

            external_session_id: null,
            external_token: null,
            waiting_room: null,

            provider: null,
            audio_permission: false,
            video_permission: false,
            message: null,

            view: "check_in", //waiting_room_not_found, check_in, first_time_access, call_session_started, end_call
        };
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleLeave);
        this.getWaitingRoom();
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeave);
        this._isUnmounted = true;
        if (this.socket) {
            this.socket.close();
        }
    }
    getWaitingRoom = () => {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms/by_slug/' + this.props.match.params.room;

        HttpClient()
        .get(apiName, path)
        .then((data) => {
            const { provider, waiting_room, presence } = { ...data };
            this.setState({
                waiting_room: waiting_room,
                provider: {
                    ...provider,
                    presence: presence
                },
                loading: false,
                view: "check_in",
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                view: "waiting_room_not_found",
            });
        });
    }
    getAudioVideoPermissionStatus = async () => {
        if (navigator.permissions !== undefined) {
            await navigator.permissions.query({ name: "microphone" }).then((result) => {
                if (result.state === "granted") {
                    this.setState({
                        audio_permission: true
                    });
                }
            }).catch((error) => {
                console.warn("Not a problem at all, navigator.permissions might not be fully supported on your browser: ", error);
            });

            await navigator.permissions.query({ name: 'camera' }).then((result) => {
                if (result.state === "granted") {
                    this.setState({
                        video_permission: true
                    });
                }
            }).catch((error) => {
                console.warn("Not a problem at all, navigator.permissions might not be fully supported on your browser: ", error);
            });
        }
        return !this.state.audio_permission || !this.state.video_permission;
    }
    handleAudioVideoPermissions = () => {
        if (navigator.mediaDevices.getUserMedia !== null) {
            const constraints = { audio: true, video: { facingMode: "user" } };
            navigator.mediaDevices.getUserMedia(constraints)
                .then(() => {
                    this.setState({
                        audio_permission: true,
                        video_permission: true,
                        view: "call_session_started",
                    });
                    countlyAddEvent("allowAudioVideo");
                })
                .catch((error) => {
                    console.log(error.name + ": " + error.message);
                    this.setState({
                        message: {
                            type: "error",
                            text: "Sorry, we need access to your camera and microphone in order to better help you.",
                        }
                    })
                    countlyAddEvent("denyAudioVideo");
                });
        }
    }
    handleLeave = () => {
        var message = {
            action: "patient_exited",
            data: {
                patient_id: this.state.patient_id,
                external_session_id: this.state.external_session_id
            }
        };
        this.socket.send(message);

        this.setState({
            view: "end_call"
        })
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        this.props.validateLive({
            ...this.state,
            [`${event.target.name}`]: event.target.value
        });
    }
    handleCheckBoxChange = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    updateClinicianStatus = (providerId, status) => {
        let provider = {...this.state.provider};
        if(provider.id === providerId){
            provider = {...provider, presence: status};
        }

        this.setState({
            provider: provider,
            providers: this.state.providers.map((provider) => {
                if(provider.id === providerId){
                    return {
                        ...provider,
                        presence: status
                    };
                }

                return provider;
            })
        });
    }
    handleSocket = async () => {
        this.socket = new Websocket();
        await this.socket.iniSocket(this.state.patient_id);

        this.socket.listen('clinician_online', (message) => {
            let providerID = message.data.provider_id;
            this.updateClinicianStatus(providerID, "available");
        });

        this.socket.listen('clinician_offline', (message) => {
            let providerID = message.data.provider_id;
            this.updateClinicianStatus(providerID, "offline");
        });

        this.socket.listen('user_connected', (message) => {
            let providerID = message.user.id;
            this.updateClinicianStatus(providerID, "in_a_call");
        });

        this.socket.listen('user_disconnected', (message) => {
            let providerID = message.user.id;
            this.updateClinicianStatus(providerID, "available");
        });

        if (this._isUnmounted) {
            this.socket.close();
        }
    }
    handleSignup = (event) => {
        event.preventDefault();

        if (!this.props.validate(this.state)) return;

        this.setState({
            loading: true,
        });

        let apiName = 'telehealthApi';
        let path = '/patients/checkin';

        let postData = {
            slug: this.props.match.params.room,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
        };

        HttpClient().post(apiName, path, postData)
        .then(async (data) => {
            this.setState({
                ...data,
                provider: data.providers ? data.providers[0] : this.state.provider,
                view: await this.getAudioVideoPermissionStatus() ? "first_time_access" : "call_session_started",
                loading: false
            });
            this.handleSocket();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    handleNoParticipants = () => {
        this.handleLeave();
    }
    render() {
        const validations = this.props.validations ? this.props.validations : {};
        const { provider, waiting_room } = this.state;

        return (
            this.state.loading ?
                <div className="container is-fluid fullheight d-flex align-items-center justify-content-center" >
                    <Loading />
                </div>
            : this.state.view === "waiting_room_not_found" ?
                <WaitingRoomNotFound />
            : this.state.view === "check_in" ?
                <CheckIn
                    first_name={this.state.first_name}
                    last_name={this.state.last_name}
                    patient_consent={this.state.patient_consent}
                    loading={this.state.loading}
                    waiting_room_name={waiting_room.name}
                    provider={provider}
                    validations={validations}
                    handleChange={this.handleChange}
                    handleSignup={this.handleSignup}
                    handleCheckboxChange={this.handleCheckBoxChange}
                />
            : this.state.view === "first_time_access" ?
                <FirstTimeCheckIn
                    first_name={this.state.first_name}
                    handleAudioVideoPermissions={this.handleAudioVideoPermissions}
                    message={this.state.message}
                />
            : this.state.view === "call_session_started" ?
                <PatientCallContainer
                    apiKey={OTConfig.API_KEY}
                    sessionId={this.state.external_session_id}
                    token={this.state.external_token}
                    firstName={this.state.first_name}
                    lastName={this.state.last_name}
                    provider={this.state.provider}
                    onLeave={this.handleLeave}
                    onNoParticipants={this.handleLeave}
                />
            : this.state.view === "end_call" ?
                <EndCall provider={provider} />
            : null
        );
    }
}

export default withValidation(PatientRoomPage, PatientFormRules);
