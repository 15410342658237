import React from 'react';
import ProviderSidebar from './partials/ProviderSidebar';
import Messages from '../shared/Messages/Messages';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import ConfigFactory from '../ConfigFactory';
import ClinicalRoomProvider from '../providers/Room/ClinicalRoomProvider';

class ProviderLayout extends React.Component {
	render() {
		return (
			<ClinicalRoomProvider>
				<div className='App fullheight is-fluid d-flex flex-column'>
					<EnvironmentBanner env={ConfigFactory.getEnv()} />
					<div className="d-flex flex-grow-1 flex-row align-items-stretch">
						<ProviderSidebar />
						<div className='flex-grow-1 has-background-light'>
							<Messages />
							{this.props.children && this.props.children}
						</div>
					</div>
				</div>
			</ClinicalRoomProvider>
		);
	}
}
export default ProviderLayout;