import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import SideCollapserFloat from './SideCollapserFloat';
import './sidebar.scss';

class RoomSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true
        };
    }
    handleCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }
    render() {
        return (
            <aside className="sidebar">
                <div className={`menu menu-sm ${this.state.isCollapsed ? 'is-hidden' : ''}`}>
                    <ul className="menu-list">
                        <li>
                            <NavLink to="/dashboard" className="has-background-danger">
                                <i className="fa fa-sign-out-alt" /> Leave meeting
                        </NavLink>
                        </li>
                    </ul>
                    <p className="menu-label">
                        &nbsp;
                    </p>
                    <ul className="menu-list">
                        <li>
                            <a href="#photo" >
                                <i className="fas fa-camera" />  Photo Capture
                            </a>
                        </li>
                        <li>
                            <a href="#photo" >
                                <i className="fas fa-users" />  Group Call
                            </a>
                        </li>
                        <li>
                            <a href="#photo" >
                                <i className="fas fa-desktop" />  Screenshare
                            </a>
                        </li>
                        <li>
                            <a href="#photo" >
                                <i className="fas fa-upload" />  File Transfer
                            </a>
                        </li>
                    </ul>
                </div>
                <SideCollapserFloat
                    onClick={this.handleCollapse}
                    isCollapsed={this.state.isCollapsed}
                />
            </aside>
        );
    }
}
export default withRouter(RoomSidebar);