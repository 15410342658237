import React from 'react';
import ReactLoading from "react-loading";
import BulmaLoader from './Loading/BulmaLoader';

const Loading = (props) => {
    return (
        <div className={'level '+props.wrapperClass}>
            <div className={'level-item'}>
                {props.type === 'BulmaLoader' ?
                    <BulmaLoader 
                        delay={props.delay}
                        width={props.width}
                        height={props.height}
                        color={props.color}
                    />
                : 
                <ReactLoading 
                    type={props.type}
                    delay={props.delay}
                    width={props.width}
                    height={props.height}
                    color={props.color}
                />
                }
            </div>
        </div>

    );
}
Loading.defaultProps= {
    type: "spin",
    delay: 0,
    width: 34,
    height: 34,
    color:"#007bff",
    wrapperClass:""
};
export default Loading;