import React from 'react';
import ProviderCallContainer from './ProviderRoom/ProviderCallContainer';
import HttpClient from '../shared/utils/HttpClient';
import Loading from "../shared/Loading";
import OTConfig from '../config/OTConfig';

class ProviderRoomPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }
    componentDidMount() {
        this.getOTSession();
    }
    getOTSession() {
        let apiName = 'telehealthApi';
        let path = '/sessions/' + this.props.match.params.session_id + '/join';
        this.setState({
            loading: true,
        });
        HttpClient().post(apiName, path)
        .then((data) => {
            this.setState({
                ...data,
                loading: false,
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        })
    }
    handleLeave = () => {
        this.props.history.push('/dashboard');
    }
    handleNoParticipants = () => {
        this.handleLeave();
    }
    render() {
        return (
            this.state.loading ?
                <div className="container is-fluid fullheight d-flex align-items-center justify-content-center" >
                    <Loading />
                </div>
                : <ProviderCallContainer
                    publisherName={this.props.authUser.fullName}
                    apiKey={OTConfig.API_KEY}
                    sessionId={this.state.external_session_id}
                    token={this.state.external_token}
                    onLeave={this.handleLeave}
                    onNoParticipants={this.handleNoParticipants}
                />
        );
    }
}
export default ProviderRoomPage;