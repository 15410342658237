import React from 'react';
import PropTypes from 'prop-types';
import './ControlBtn.scss';

const ScreenShareBtn = (props) => {
    return (
        <div id="ctrl-btn-screen" className={`call-control`} onClick={props.onClick} >
            <div className="ctr-btn" >
                <span className={`ic-share-screen`} />
            </div>
            <div className="ctr-label">
                {props.isOn ? 'Stop sharing' : 'Share screen'}
            </div>
        </div>
    );
}
ScreenShareBtn.propTypes = {
    onClick: PropTypes.func,
    isOn: PropTypes.bool
};
export default ScreenShareBtn;