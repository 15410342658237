import React from 'react';
import { OTSubscriber } from 'opentok-react';
import './VideoContainer.scss';

class Subscriber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            audio: true,
            video: true,
            videoElement: null,
        };
        this.eventHandlers = {
            videoElementCreated: (event) => {
                this.setState({videoElement: event.element});
                if(this.props.speaker){
                    this.setSpeakerSource(this.props.speaker, event.element);
                }
                if(this.props.onVideoElementCreated) this.props.onVideoElementCreated(event.element);
            }
        };
    }
    componentDidMount(){
        this.props.onChangeAudio(!this.props.stream.hasAudio)
        this.props.session.on('streamPropertyChanged', (event) => {
            if(event.stream.id === this.props.stream.id){
                switch(event.changedProperty){
                    case 'hasAudio':
                    default:
                        this.props.onChangeAudio(!event.newValue)
                    break;
                }
            }
		});
    }
    componentDidUpdate(prevProps){
        if(prevProps.speaker !== this.props.speaker){
			this.setSpeakerSource(this.props.speaker);
		}
    }
    toggleAudio = () => {
        this.setState({
            audio: !this.state.audio
        });
    }
    onError = (err) => {
        this.setState({ error: `Failed to subscribe: ${err.message}` });
    }
    setSpeakerSource = async (deviceId, videoElement = null) => {
        videoElement = videoElement ? videoElement : this.state.videoElement;
        
        if(!videoElement) return;

        if (typeof videoElement.sinkId !== 'undefined') {
            try {
                await videoElement.setSinkId(deviceId);
            } catch (err) {
                console.log('Could not update speaker ', err);
            }
        }
    }
    render() {
        return (
            <div className="video-container subscriber">
                {this.state.error ? <div iclassNamed="error">{this.state.error}</div> : null}
                
                {/* <div className="video-status-bar level">
                    <div className="mini-controls level-right">
                        <div className="btn-control level-item" onClick={this.toggleAudio} >
                            <i className={`fas ${this.state.audio ? 'fa-volume-up' : 'fa-volume-mute has-text-danger'}`} />
                        </div>
                    </div>
                </div> */}

                <OTSubscriber
                    session={this.props.session}
                    key={this.props.stream.id}
                    stream={this.props.stream}
                    eventHandlers={this.eventHandlers}
                    className="video"
                    properties={{
                        ...this.props.properties,
                        showControls: false,
                        subscribeToAudio: this.state.audio,
                        subscribeToVideo: this.state.video
                    }}
                    onError={this.onError}
                />
            </div>
        );
    }
}
export default Subscriber;