import React from 'react';
import Countly from 'countly-sdk-web';
import Avatar from '../../shared/Avatar';
import Input from '../../shared/Input';
import Modal from "../../shared/Modal/Modal";
import Checkbox from "../../shared/Checkbox";
import AVATAR_COLOR_STATUS from '../../shared/constants/AVATAR_COLOR_STATUS';
import './CheckIn.scss';
import {countlyAddEvent} from '../../countly';

class CheckIn extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        Countly.track_pageview("checkIn");
    }
    handleSignup = (event) => {
        const { provider } = this.props;
        if(provider.first_name) countlyAddEvent("typedFirstName");
        if(provider.last_name) countlyAddEvent("typedLastName");
        if(this.props.patient_consent) countlyAddEvent("checkCheckInConsent");
        countlyAddEvent("clickCheckIn");
        this.props.handleSignup(event);
    }
    render(){
        const { provider, validations, waiting_room_name } = this.props;
        const termsAndConditionLink = <span>I consent to the <a href="https://www.villagemedical.com/terms-and-conditions">Virtual Visit Terms</a></span>;
    
        return (
            <Modal isModalFullScreen={true} isOpen={true}>
                <div className="box">
                    <div className="media-left my-4 is-hidden-tablet">
                        <figure>
                            <img src="../../village-medical-dark-logo-60.png" width="155" height="28" alt="village medical logo" />
                        </figure>
                    </div>
                    <form id="patient-form" className="my-5" method='POST' onSubmit={this.handleSignup} >
                        <h2 className="has-text-centered has-text-weight-bold is-size-4">Welcome!</h2>
                        <p className="has-text-centered is-size-6 mb-5" >
                            Please check in below to let {waiting_room_name} know you are here
                        </p>
    
                        <div className="media my-5 clinician-card">
                            {/* <div className="media-left">
                                <figure className="image is-64x64">
                                    <img className="is-rounded" src="https://bulma.io/images/placeholders/96x96.png" alt="Village Medical Clinician Photo"/>
                                </figure>
                            </div> */}
                            <div className="media-left">
                                <Avatar
                                    first_name={provider.first_name}
                                    last_name={provider.last_name}
                                    avatarColor={AVATAR_COLOR_STATUS[provider.presence]}
                                />
                            </div>
                            <div className="media-content is-pulled-left">
                                <div className="column is-vcentered">
                                    <p className="subtitle is-7">Appointment with</p>
                                    <p className="title has-text-weight-bold is-6">{provider.first_name} {provider.last_name}</p>
                                </div>
                            </div>
                        </div>
    
                        <div className="my-4">
                            <div className="columns is-centered" >
                                <div className="column" >
                                    <Input
                                        className="is-medium"
                                        autoFocus={true}
                                        label="First name"
                                        name='first_name'
                                        placeholder='Enter your first name'
                                        value={this.props.first_name}
                                        onChange={this.props.handleChange}
                                        validation={validations.first_name}
                                    />
                                    <Input
                                        className="is-medium"
                                        label="Last name"
                                        name='last_name'
                                        placeholder='Enter your last name'
                                        value={this.props.last_name}
                                        onChange={this.props.handleChange}
                                        validation={validations.last_name}
                                    />
                                    <div className="level-item is-pulled-left my-4">
                                        <Checkbox
                                            name="patient_consent"
                                            checked={this.props.patient_consent}
                                            value={this.props.patient_consent}
                                            onChange={this.props.handleCheckboxChange}
                                            validation={validations.patient_consent}
                                            label={termsAndConditionLink}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="has-text-centered">
                            <button className={`button is-primary is-medium is-fullwidth ${this.props.loading ? 'is-loading' : ''}`} >
                                Check In
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}
export default CheckIn;
