import Authorizer from './../Authorizer';

class OneLoginUser {
    constructor(User) {
        this.type = "onelogin";
        this.originalUser = User;
    }
    get attributes() {
        return this.originalUser;
    }
    get sub() {
        return this.attributes.sub;
    }
    get email() {
        return this.attributes.email;
    }
    set email(value) {
        
    }
    get username() {
        return this.attributes.preferred_username;
    }
    set username(value) {

    }
    set policies(newPermissions) {
        Authorizer.setPermissions(newPermissions);
    }
    get fullName() {
        return this.attributes.name;
    }
}
export default OneLoginUser;