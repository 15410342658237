import React from 'react';
import PropType from 'prop-types';
import Loading from '../../shared/Loading';
import './WaitingParticipant.scss';

class WaitingParticipant extends React.Component {
    isVisibleBtnEnableAudioVideo = () => {
		return this.props.isVideoDisabled || this.props.isAudioDisabled
	}
	getTextBtnEnableAudioVideo = () => {
		return this.props.isVideoDisabled && !this.props.isAudioDisabled ?
			'Video'
		: !this.props.isVideoDisabled && this.props.isAudioDisabled ?
			'Sound'
		: 'Video and Sound';
	}
    render(){
        return (
            <div className="waiting-participant">
                <div className="has-text-centered">
                    <Loading type="BulmaLoader" color="#FFFFFF" />
                    <p className="title has-text-white has-text-weight-medium is-6">
                        Please wait a few moments
                    </p>
                    <p className="subtitle has-text-white is-7">
                        Finalizing connection {this.props.participantName && `with ${this.props.participantName}`}
                    </p>
        
                    { this.isVisibleBtnEnableAudioVideo() &&
                        <button className="button is-danger" onClick={this.props.onEnableAudioVideo} >
                            Turn on {this.getTextBtnEnableAudioVideo()}
                        </button>
                    }
                </div>
            </div>
        );
    }
}
WaitingParticipant.propType = {
    isAudioDisabled: PropType.bool,
    isVideoDisabled: PropType.bool,
    participantName: PropType.string,
    onEnableAudioVideo: PropType.func,
};
export default WaitingParticipant;