import React from 'react';
import PropTypes from 'prop-types';
import Subscriber from './Subscriber';
import VIDEO_RESOLUTIONS from '../../shared/constants/VIDEO_RESOLUTIONS';
import './FocusedVideo.scss';

class FocusedVideo extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			size: {
				height: '100%',
				width: '100%'
			},
		};
	}
	handleQuality = (quality) => {
		switch(quality){
			case 'high':
				this.props.stream.setPreferredResolution(VIDEO_RESOLUTIONS.high);
			break;
			case 'medium':
				this.props.stream.setPreferredResolution(VIDEO_RESOLUTIONS.medium);
			break;
			case 'low':
			default:
				this.props.stream.setPreferredResolution(VIDEO_RESOLUTIONS.low);
			break;
		}
	}
	render(){
		return (
			<div id="focused-video" className="focused-video">
				{this.state.size &&
					<Subscriber
						session={this.props.session}
						stream={this.props.stream}
						speaker={this.props.speaker}
						onVideoElementCreated={this.props.onVideoElementCreated}
						properties={{
							name: this.props.stream.name,
							fitMode: "cover",
							...this.state.size,
						}}
						onChangeAudio={this.props.onChangeAudio(this.props.stream)}
					/>
				}
			</div>
		);
	}
}
FocusedVideo.propTypes = {
	session: PropTypes.object,
	stream: PropTypes.object,
	size: PropTypes.object,
	properties: PropTypes.object,
	onVideoElementCreated: PropTypes.func
};
export default FocusedVideo;
