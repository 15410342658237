import React from 'react';
import {withRouter} from 'react-router-dom';
import NavLink from "../../core/router/NavLink";
import Loading from "../../shared/Loading";
import InputSearch from "../../shared/InputSearch";
import {CheckPermission} from '../../shared/utils/Authorizer';

class ListWaitingRooms extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            search: ""
        };
    }
    handleSearch = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    filterWaitingRooms = () => {
        return this.props.waiting_rooms.filter((waiting_room)=>{
            if(this.state.search === "" || this.state.search === null || this.state.search === undefined) {
                return true;
            }

            return (waiting_room.first_name.toLowerCase()
                + waiting_room.last_name.toLowerCase()
                ).includes(this.state.search.toLowerCase())
        });
    }
    openForm = (waiting_room_id) => (event) => {
        if(waiting_room_id === 'new')
            return this.props.history.push('/waiting_rooms/new');
        else
            return this.props.history.push('/waiting_rooms/edit/'+waiting_room_id);
    }
    render(){
        return (
            <CheckPermission act='GET' obj={'/waiting_rooms'} >
                <div>
                    <div className="level">
                        <div className="level-left" >
                            <div className="level-item" >
                                <h1 className="title">Waiting rooms</h1>
                            </div>
                            <CheckPermission act='POST' obj={'/waiting_rooms'} >
                                <div className="level-item" >
                                    <button className="button is-primary is-small" onClick={this.openForm('new')} >
                                        <i className="fas fa-plus" /> &nbsp;new
                                    </button>
                                </div>
                            </CheckPermission>
                        </div>
                        <div className="level-right" >
                            <div className="level-item" >
                                <InputSearch value={this.state.search} name="search" onChange={this.handleSearch} />
                            </div>
                        </div>
                    </div>
                    <table id="waiting_room-list" className="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Slug</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.loading ?
                            <tr id="loading-row" >
                                <td colSpan={5} >
                                    <Loading />
                                </td>
                            </tr>
                        : this.filterWaitingRooms().map((waiting_room) => 
                            <tr key={waiting_room.id} id={"waiting_room-"+waiting_room.id} >
                                <td>{waiting_room.name}</td>
                                <td>{waiting_room.description}</td>
                                <td>{waiting_room.slug}</td>
                                <td>
                                    <CheckPermission act='DELETE' obj={`/waiting_rooms/${waiting_room.id}`} >
                                        <span className="pointer has-text-danger is-size-7" onClick={this.props.onRemove(waiting_room)} >
                                            Remove
                                        </span>
                                    </CheckPermission>
                                    &nbsp;&nbsp;

                                    <CheckPermission act='GET' obj={`/waiting_rooms/${waiting_room.id}`} >
                                        <NavLink to={"/waiting_rooms/edit/"+waiting_room.id} >
                                            Open <i className="fas fa-chevron-right" />
                                        </NavLink>
                                    </CheckPermission>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </CheckPermission>
        );
    }
}
export default withRouter(ListWaitingRooms);