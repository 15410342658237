export default [
	{
		code: 'first_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'First name cannot be empty',
				rule: ['required'],
			}
		}
	},
	{
		code: 'last_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Last name cannot be empty',
				rule: ['required'],
			},
		}
	},
	{
		code: 'email',
		element_type: 'input',
		rules: {
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'E-mail is not valid',
				rule: ['email'],
			},
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'E-mail cannot be empty',
				rule: ['required'],
			},
		}
	},
	{
		code: 'phone_number',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Phone cannot be empty',
				rule: ['required'],
			},
			phone: {
				type: 'error',
				error_type: 'hard',
				message: 'This is not a valid phone number.',
				rule: ['phone'],
			}
		}
	}
];
