import React from 'react';

const SlimSidebar = (props) => {
    return (
        <aside class="sidebar sidebar-sm d-flex flex-column">
            <figure className="has-text-centered">
                <img className="logo-collapse" src="../../docOS.fav.png" alt="docOS logo" />
            </figure>

            {props.children && props.children}

            <div className="px-3 py-3">
                <button className="button is-light is-small px-4 is-fullwidth" onClick={props.onCollapse} >
                    <i className="fas fa-angle-double-right" />
                </button>
            </div>
        </aside>
    );
}
export default SlimSidebar;