import React from 'react';
import PropTypes from 'prop-types';
import './ControlBtn.scss';

class PipBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    componentDidMount(){
        this.setPipOnWindowBlur();
    }
    setPipOnWindowBlur = () => {
        window.addEventListener('blur', () => {
            if (!document.pictureInPictureElement) {
                this.handlePiP();
            }
        });
    }
    isPiPEnabled = () => {
        return document.pictureInPictureEnabled && this.props.focusedVideoElement;
    }
    closePip = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
            window.removeEventListener('click', this.closePip);
        }
    }
    handlePiP = () => {
        if (this.isPiPEnabled()) {
            try {
                if (!document.pictureInPictureElement) {
                    this.props.focusedVideoElement.requestPictureInPicture()
                        .catch((msg) => console.warn(msg));

                    window.addEventListener('click', this.closePip.bind(this));
                } else {
                    document.exitPictureInPicture();
                }
            } catch (err) {
                // Video failed to enter/leave Picture-in-Picture mode.
                console.error("Video failed to enter/leave Picture-in-Picture mode.");
            }
        }
    }
    render(){
        return (
            this.isPiPEnabled() ?
                <div className={`call-control`} onClick={this.handlePiP} >
                    <div className="ctr-btn" >
                        <span className={`ic-picture-in-picture`} />
                    </div>
                    <div className="ctr-label">
                        PiP
                    </div>
                </div>
            : null
        );
    }
}
PipBtn.propTypes = {
    focusedVideoElement: PropTypes.any
};
export default PipBtn;