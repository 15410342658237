import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import OidcLoginPage from './pages/Auth/OidcLoginPage';
import OidcCallbackPage from './pages/Auth/OidcCallbackPage';

//Cognito authentication page components:
import LoginPage from './pages/Auth/LoginPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import ChangePasswordPage from './pages/Auth/ChangePasswordPage';

import PatientRoomPage from './pages/PatientRoomPage';
import ProviderRoomPage from './pages/ProviderRoomPage';

import ProviderLayout from './layouts/ProviderLayout';
import RoomLayout from './layouts/RoomLayout';
import ProfilePage from './pages/ProfilePage';
import DashboardPage from './pages/DashboardPage';
import ProvidersPage from './pages/Providers/ProvidersPage';
import WaitingRoomsPage from './pages/WaitingRooms/WaitingRoomsPage';
import Error404Page from './pages/Error404Page';

import consumeApp from './providers/consumeApp';
import { MyRoute } from './core/router';
import Loading from './shared/Loading';
import ConfigFactory from './ConfigFactory';
import CountlyTracker from './countly/CountlyTracker';
import './App.scss';

import withMessages from './shared/Messages/withMessages';
import HttpClientPlugins from './shared/utils/HttpClient/HttpClientPlugins';

const history = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        ConfigFactory.init();
        props.checkCurrentUser();
        props.getWebClientInfo();

        HttpClientPlugins.push((client) => {
            client.on('HTTP_STATUS_401', (event) => {
                props.showMessage({
                    message: `Unauthorized: ${event.response.data.detail}`
                })
                client.stopRetry();
            });

            return client;
        });
    }
    componentDidCatch(error, errorInfo) {

    }
    render() {
        return (
            <Router history={history} >
                <CountlyTracker>
                {this.props.checkingAuthentication ?
                    <div className="container is-fluid fullheight has-background-light d-flex align-items-center justify-content-center" >
                        <Loading />
                    </div>
                    :
                    <Switch>
                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' ?
                            <MyRoute exact path='/change-password' component={ChangePasswordPage} private redirectTo='/login' />
                            :
                            <Redirect from='/change-password' to='/' />
                        }

                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' &&
                            <Redirect to={"/change-password"} />
                        }
                        <MyRoute exact path='/login' component={LoginPage} onlyPublic redirectTo='/dashboard' />
                        <MyRoute exact path='/forgot-password' component={ForgotPasswordPage} onlyPublic redirectTo='/dashboard' />

                        {/* <Redirect exact from='/login' to="/oidc/login" /> */}
                        <MyRoute exact path='/oidc/login' component={OidcLoginPage} onlyPublic redirectTo='/dashboard' />
                        <MyRoute exact path='/oidc/callback' component={OidcCallbackPage} onlyPublic redirectTo='/dashboard' />
                        <MyRoute exact path='/session/:session_id' private component={RoomLayout} redirectTo='/login'>
                            <MyRoute exact path='/session/:session_id' private component={ProviderRoomPage} redirectTo='/login' />
                        </MyRoute>

                        <MyRoute path='/:path(|dashboard|profile|providers|waiting_rooms)' component={ProviderLayout} private redirectTo="/login" >
                            <Switch>
                                <Redirect exact from='/' to="/dashboard" />
                                <MyRoute exact path='/dashboard' component={DashboardPage} redirectTo='/login' />
                                <MyRoute path='/providers' title={"Providers"} component={ProvidersPage} redirectTo='/login' />
                                <MyRoute path='/waiting_rooms' title={"Waiting Rooms"} component={WaitingRoomsPage} redirectTo='/login' />
                                <MyRoute exact path='/profile' component={ProfilePage} private redirectTo='/login' />
                                <MyRoute path="/" component={Error404Page} />
                            </Switch>
                        </MyRoute>

                        {!this.props.isLoggedIn &&
                            <MyRoute exact path='/:room' onlyPublic component={PatientRoomPage} />
                        }
                        <MyRoute path="/not-found" component={Error404Page} />
                        <MyRoute path="/" component={Error404Page} />

                    </Switch>
                }
                </CountlyTracker>
            </Router>
        );
    }
}
export default consumeApp(withMessages(App));
