import React from 'react';
import './Modal.scss';

const Modal = (props) => {
    return (
        <div className={`modal ${props.isModalFullScreen ? "modal-full-screen" : ""} ${props.isOpen ? "is-active" : ""}`}>
            <div className={`modal-background ${props.transparent && 'transparent'}`} onClick={props.onClose}>
                {!props.transparent &&
                <div className="media-left mx-5 my-5 is-hidden-mobile">
                    <figure>
                        <img src="../../village-medical-light-logo-60.png" width="155" height="28" alt="docOS logo" />
                    </figure>
                </div>}
            </div>
            <div className="modal-content full-screen">
                {props.children}
            </div>
            {props.onClose &&
                <button className="modal-close is-large" aria-label="close" onClick={props.onClose} ></button>
            }
        </div>
    );
};
Modal.defaultProps = {
    transparent: false 
};
export default Modal;
