import WebSocket from 'isomorphic-ws';
// import { Auth, Signer } from "aws-amplify"
import ConfigFactory from '../ConfigFactory';

/*
    Resources:
        https://aws.amazon.com/blogs/compute/announcing-websocket-apis-in-amazon-api-gateway/
        https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-how-to-call-websocket-api-connections.html
        https://github.com/serverless/serverless/issues/6149
        https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-websocket-control-access-iam.html
        https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
        https://medium.com/build-succeeded/using-web-socket-aws-api-gateway-to-allow-your-event-driven-lambda-based-api-to-push-data-to-your-212855db344b
        https://medium.com/@sumindaniro/secure-aws-api-gateway-using-a-lambda-authorizer-40a1da825b16
        https://www.youtube.com/watch?v=3SCdzzD0PdQ

        https://medium.com/@kavitanambissan/real-time-updates-in-your-react-app-with-amazon-websocket-api-gateway-aws-lambda-mongodb-6341a4dd57d5
        https://medium.com/@kavitanambissan/react-chat-app-with-amazon-websocket-api-gateway-and-aws-lambda-part-ii-c9b10f114a13
        https://github.com/kvnam/rc-chat-front/tree/master/src

        // Chat
        https://codeburst.io/how-to-build-a-react-chat-app-with-aws-api-gateway-websockets-and-cognito-custom-authorizer-6f84f2da47ec

*/
class MySocket {
    constructor() {
        this.ws = null;
        this.listeners = {};
        this.queueMessages = [];
    }
    iniSocket = async (userId) => {
        // const credentials = await Auth.currentCredentials()
        // const accessInfo = {
        //     access_key: credentials.accessKeyId,
        //     secret_key: credentials.secretAccessKey,
        //     session_token: credentials.sessionToken,
        // }
        const wsEndpoint = ConfigFactory.getEndpoint('teleHealthSocket');
        const wssUrl = wsEndpoint.endpoint + userId;

        // const signedUrl = Signer.signUrl(wssUrl, accessInfo)
        // this.ws = new WebSocket(signedUrl); // connectionID in memory. 
        this.ws = new WebSocket(wssUrl); // direct connect as of now. 

        this.ws.onopen = () => {
            this.resumeSending();
        }
        this.ws.onmessage = (msg) => {
            var message = JSON.parse(msg.data);
            if (this.listeners[message.action]) {
                this.listeners[message.action](message);
            }
        }
    }
    onOpen = (func) => {
        this.ws.onopen = func;
    }
    onClose = (func) => {
        this.ws.onclose = func;
    }
    readyState = () => {
        return this.ws.readyState;
    }
    listen = (event, func) => {
        this.listeners[event] = func;
    }
    send = (data) => {
        if (this.readyState() !== 1) {
            this.queueMessages.push(data);
        } else if (this.readyState() === 1) {
            this.ws.send(JSON.stringify(data));
        }
    }
    resumeSending = () => {
        while (this.readyState() === 1 && this.queueMessages.length > 0) {
            let msg = this.queueMessages.pop();
            this.send(msg);
        }
    }
    close = () => {
        if (this.ws) {
            this.ws.close();
        }
    }
};
export default MySocket;