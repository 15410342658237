import React from 'react';
import PropTypes from 'prop-types';
import Countly from 'countly-sdk-web';
import FocusedVideo from './FocusedVideo';
import WaitingParticipant from './WaitingParticipant';
import MiniVideos from './MiniVideos';
import './VirtualRoom.scss';

class VirtualRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        Countly.track_pageview("inCall");
    }
    isFocused = (stream) => {
        const callSession = this.props.callSession;
        return callSession.focusedStream && stream.id === callSession.focusedStream.id;
    }
    render() {
        const callSession = this.props.callSession;
		return (
			<React.Fragment>  
                {callSession.focusedStream ?
                <FocusedVideo
                    session={callSession.session}
                    stream={callSession.focusedStream}
                    speaker={callSession.speaker}
                    onVideoElementCreated={callSession.handleFocusedVideoCreated}
                    onChangeAudio={callSession.handleChangeParticipantAudio}
                />
                : 
                    <WaitingParticipant 
                        isVideoDisabled={!callSession.video}
                        isAudioDisabled={!callSession.audio}
                        onEnableAudioVideo={callSession.handleEnableAudioVideo}
                    />
                }

                <MiniVideos
                    session={callSession.session}
                    publisher={{
                        name: this.props.publisherName,
                        publishAudio: callSession.audio,
                        publishVideo: callSession.video,
                        facingMode: callSession.facingMode,
                        screenShare: callSession.screenShare,
                    }}
                    streams={callSession.streams.filter((stream)=> !this.isFocused(stream) )}
                    onFocus={callSession.handleFocus}
                    onChangeAudio={callSession.handleChangeParticipantAudio}
                />      
            </React.Fragment>
        );
    }
}
VirtualRoom.proptTypes = {
	publisherName: PropTypes.string,
	callSession: PropTypes.object,
}
export default VirtualRoom;
