import React from 'react';
import './VideoOff.scss';

const VideoOff = (props) => {

	return (
		<div className="video-off mini-video-preview h100 w100 d-flex justify-content-center align-items-center flex-column" >
            <div className="icon-container" >
                <span className="ic-video-off-white" />
            </div>
            <span className="has-text-white" >Your video is turned off</span>
		</div>
	);
}
export default VideoOff;