import React from 'react';
import { Auth } from 'aws-amplify';
import Input from '../shared/Input';

import withValidation from '../validations/withValidation';
import ProfileFormRules from '../validations/FormRules/ProfileFormRules';

class ProfilePage extends React.Component{
    constructor(props){
        super(props);
        const userData = this.props.authUser.attributes;
        this.state = {
            email: userData['email'],
            phoneNumber: userData['phone_number'],
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        };
    }
    handleLogout = () => {
        this.props.logout();
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state,
            [`${event.target.name}`]: event.target.value
        });
    }
    handleChangeDate = (name) => (value) => {
        this.setState({
            [name]: value
        });
        this.props.validateLive({
            ...this.state,
            [`${name}`]: value
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        
        //Trigger validation before submit:
        if (!this.props.validate(this.state)) return;

        if (this.state.newPassword !== this.state.confirmNewPassword){
            return this.setState({
                loading: false,
                message: 'New Passwords do not match.',
                messageType: 'message is-danger'
            });
        };
        
        this.setState({
            loading: true
        });
        try {
            // Auth.updateUserAttributes(this.props.authUser, {
            //     'email': this.state.email,
            //     //'custom:phoneNumber': this.state.phoneNumber,
            //     'phone_number': this.state.phoneNumber,
            // })
            // .then((data) => {
            //     this.setState({
            //         loading: false,
            //         message: null,
            //     });
            // }).catch((error) => {
            //     this.setState({
            //         loading: false,
            //         message: error.message
            //     });
            // });

            Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
            })
            .then(() => {
                this.setState({
                    loading: false,
                    message: 'Password updated successfully!',
                    messageType: 'message is-success',
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    message: error.message,
                    messageType: 'message is-danger'
                });
            });

        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }
    render(){
        return (
            <div className="py-5 px-5">
                <div className='columns is-vcentered is-centered'>
                    <div className='column is-4'>
                        <div className='box'>
                            <div className="is-clearfix" />
                            <form id='profilePage-form' method='POST' onSubmit={this.handleSubmit} >
                                {this.state.message &&
                                <article className={this.state.messageType}>
                                    <div className='message-body'>
                                        {this.state.message}
                                    </div>
                                </article>}
                                
                                <Input 
                                    label='Email' 
                                    name='email' 
                                    value={this.state.email} 
                                    //onChange={this.handleChange} 
                                    disabled
                                    validation={this.props.validations.email}
                                />

                                <Input 
                                    label='Phone Number' 
                                    name='phoneNumber' 
                                    value={this.state.phoneNumber} 
                                    //onChange={this.handleChange} 
                                    disabled
                                    validation={this.props.validations.phoneNumber} 
                                />

                                <Input 
                                    label='Current Password'
                                    type='password'
                                    name='oldPassword'
                                    value={this.state.oldPassword}
                                    onChange={this.handleChange}
                                    validation={this.props.validations.oldPassword} 
                                />

                                <Input 
                                    label='New Password'
                                    type='password'
                                    name='newPassword'
                                    value={this.state.newPassword}
                                    onChange={this.handleChange}
                                    validation={this.props.validations.newPassword} 
                                />

                                <Input 
                                    label='Confirm New Password'
                                    type='password'
                                    name='confirmNewPassword'
                                    value={this.state.confirmNewPassword}
                                    onChange={this.handleChange}
                                    validation={this.props.validations.confirmNewPassword} 
                                />
                                
                                <div className='field'>
                                    <div className='control'>
                                        <div>
                                        </div>
                                        {/* <button className={`button is-link ${this.state.loading ? 'is-loading' : ''}`} type='submit' > */}
                                        <button className={`button is-primary is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                            Save changes
                                        </button>
                                    </div>
                                </div>

                                <div className="content is-small">
                                    <h4>Password Requirements</h4>
                                    <ul className="is-lower-alpha">
                                        <li>Minimum of 8 characters</li>
                                        <li>Must contain one number</li>
                                        <li>Must contain one uppercase letter</li>
                                        <li>Must contain one lowercase letter</li>
                                        <li>Must contain one special character</li>
                                    </ul>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
export default withValidation(ProfilePage, ProfileFormRules);