import React from 'react';
import { Auth } from 'aws-amplify';
import Input from '../../shared/Input';
import consumeApp from '../../providers/consumeApp';
import MessageInline from '../../shared/MessageInline';
import withValidation from '../../validations/withValidation';
import ChangePasswordFormRules from '../../validations/FormRules/ChangePasswordFormRules';

class ChangePasswordPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            message: null
        };
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state,
            [`${event.target.name}`]: event.target.value
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        
        //Trigger validation before submit:
        if (!this.props.validate(this.state)) return;

        this.setState({
            loading: true
        });
        
        const {originalUser} = this.props.authUser;
        Auth.completeNewPassword(originalUser, this.state.newPassword)
        .then(user => {
            // at this time the user is logged in if no MFA required
            this.setState({
                loading: false
            });
            
            this.props.checkCurrentUser();

        }).catch(e => {
            this.setState({
                loading: false,
                message: {
                    type: "error",
                    text: "Password change failed"
                }
            });
        });
    }
    render(){
        return (
            <div className='columns is-vcentered is-centered fullheight'>
                <div className='column is-4'>
                    <div className='box'>
                        <br/>
                        <div className="is-clearfix" />
                        <form id="changePassword-form" method='POST' onSubmit={this.handleSubmit} >
                            
                            {this.state.message && 
                                <MessageInline {...this.state.message} />
                            }

                            <Input 
                                label='New Password'
                                type='password'
                                name='newPassword'
                                value={this.state.newPassword}
                                onChange={this.handleChange}
                                validation={this.props.validations.newPassword} 
                            />

                            <Input 
                                label='Confirm New Password'
                                type='password'
                                name='confirmNewPassword'
                                value={this.state.confirmNewPassword}
                                onChange={this.handleChange}
                                validation={this.props.validations.confirmNewPassword} 
                            />
                            
                            <div className='field'>
                                <div className='control'>
                                    <button className={`button is-primary is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                        Change password
                                    </button>
                                </div>
                            </div>

                            <div className="content is-small">
                                <h4>Password Requirements</h4>
                                <ul className="is-lower-alpha">
                                    <li>Minimum of 8 characters</li>
                                    <li>Must contain one number</li>
                                    <li>Must contain one uppercase letter</li>
                                    <li>Must contain one lowercase letter</li>
                                    <li>Must contain one special character</li>
                                </ul>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    };
}
export default consumeApp(withValidation(ChangePasswordPage, ChangePasswordFormRules));