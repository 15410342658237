import React from 'react';
import './ModalConfirm.scss';

const ModalConfirm = (props) => {
    return (
        <div className={`modal modal-confirm ${props.isOpen && 'is-active'}`}>
            <div className="modal-background" ></div>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <h5 className="title">{props.title}</h5>
                    </div>
                    <footer className="card-footer b-n">
                        <button className="button is-danger confirm" onClick={props.onConfirm}>
                            {props.confirmText}
                        </button>
                        <button className="button is-text is-inverted cancel" onClick={props.onCancel} >
                            {props.cancelText}
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    );
}
export default ModalConfirm;