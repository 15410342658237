import React from 'react';
import { withRouter } from 'react-router-dom';
import consumeApp from '../../providers/consumeApp';
import withClinicalRoom from '../../providers/Room/withClinicalRoom';
import ProviderSlimSidebar from './ProviderSidebar/ProviderSlimSidebar';
import ProviderNormalSidebar from './ProviderSidebar/ProviderNormalSidebar';
import './sidebar.scss';

class ProviderSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false
        };
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleLogout);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLogout);
    }
    handleCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }
    handleLogout = () => {
        this.props.handleClinicianGoOffline();
        this.props.logout();
    }
    render() {
        return (
            this.state.isCollapsed ?
                <ProviderSlimSidebar
                    onCollapse={this.handleCollapse}
                    onLogout={this.handleLogout}
                />
                : <ProviderNormalSidebar
                    onCollapse={this.handleCollapse}
                    onLogout={this.handleLogout}
                />
        );
    }
}
export default withRouter(consumeApp(withClinicalRoom(ProviderSidebar)));
