import React from 'react';
import AudioLevelContext from './AudioLevelContext';

function withAudioLevel(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <AudioLevelContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </AudioLevelContext.Consumer>
            );
        }
    };
}
export default withAudioLevel;