import React from 'react';
import withMessages from './withMessages';
import './es_alert.scss';

class Messages extends React.Component{
	dismissTimeout(msg){
		setTimeout(()=>{
			this.props.dismiss(msg.uuid);
		}, this.props.timeout)
		
		return null;
	}
	dismissNow = (uuid) => (event) => {
		this.props.dismiss(uuid);
	}
	render(){
		const positionClass = this.props.position.toLowerCase();
		const {messages} = this.props;
		
		return(
			messages.length > 0 ?
				<div className={"es-alert-container "+positionClass} >
					{messages.map( (msg, i) => i < this.props.maxStack &&
					<div className={"es-alert"} key={"msg-alert-"+i} >
						{msg.message}
						{this.props.timeout && this.dismissTimeout(msg)}
					</div>)}
				</div>
			: null
		);
	}
}
Messages.defaultProps = {
	position: "middle center",
	timeout: 3000, //ms
	maxStack: 5,
};
export default withMessages(Messages);
