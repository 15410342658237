const awsconfig = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:xxxxxxxxxxxxx", 
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_xxxxxxxxxxxxx",
    "aws_user_pools_web_client_id": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "xxxxxxxxxxxxx",
            "endpoint": "https://xxxxxxxxxxxxx.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ]
};

export default awsconfig;