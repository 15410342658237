import React from 'react';
import Subscriber from './Subscriber';

class MiniSubscriber extends React.Component{
	constructor(props){
		super(props);
		this.state={};
	}
	handleClick = () => {
		if(this.props.onClick) this.props.onClick(this.props.stream);
	}
	render(){
		
		return(
			<div className={`mini-video ${this.props.isFocused ? 'focused' : ''}`} onClick={this.handleClick} style={{...this.props.style}} >
				<Subscriber
					key={this.props.stream.id}
					session={this.props.session}
					stream={this.props.stream}
					speaker={this.props.speaker} 
					properties={{
						name: this.props.stream.name,
						width: '200px',
						height: '125px',
						...this.props.properties
					}}
					onChangeAudio={this.props.onChangeAudio(this.props.stream)}
				/>
			</div>
		);
	}
}
MiniSubscriber.defaultProps = {
	isFocused: false,
}
export default MiniSubscriber;