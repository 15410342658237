import React from 'react';
import { NavLink } from 'react-router-dom';
import SlimSidebar from '../Sidebar/SlimSidebar';
import {CheckPermission} from '../../../shared/utils/Authorizer';

const ProviderSlimSidebar = (props) => {
    return (
        <SlimSidebar onCollapse={props.onCollapse}>
            <div className={`menu flex-grow-1`}>
                <ul class="menu-list">
                    <li className="has-text-centered">
                        <NavLink to="/dashboard">
                            <i className="fa fa-tachometer-alt" />
                        </NavLink>
                    </li>
                    <CheckPermission act='GET' obj={'/providers'} >
                        <li className="has-text-centered">
                            <NavLink to="/providers">
                                <i className="fa fa-users" />
                            </NavLink>
                        </li>
                    </CheckPermission>
                    <CheckPermission act='GET' obj={'/waiting_rooms'} >
                        <li className="has-text-centered">
                            <NavLink to="/waiting_rooms">
                                <i className="fas fa-clinic-medical" />
                            </NavLink>
                        </li>
                    </CheckPermission>
                </ul>
            </div>
            <div className="px-3 py-3">
                <button class="button is-info is-outlined is-small is-fullwidth px-4" onClick={props.onLogout} >
                    <i className="fas fa-sign-out-alt" />
                </button>
            </div>

        </SlimSidebar >
    );
}
export default ProviderSlimSidebar;