import React from 'react';
import qs from 'qs';
import OneloginAuth from '../../OneloginAuth';
import consumeApp from '../../providers/consumeApp';
import Loading from '../../shared/Loading';
import HttpClient from '../../shared/utils/HttpClient';

class OidcCallbackPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.exchangeOneLoginCodeWithTokens();
    }
    exchangeOneLoginCodeWithTokens(){
        /**
             * Make request with "code" to get accessToken and idToken.
             * The request can be made on our backend or directly on the auth_service.
            */
        let apiName = 'telehealthApi';
        let path = '/users/oidc/callback';
        let parts = window.location.href.split("?");
        let params = parts.length > 1 ? parts[1] : "";
        params = qs.parse(params);

        HttpClient()
        .get(apiName, path, params)
        .then((data) => {
            OneloginAuth.setAuthData(data);
            this.props.login(data.userData, 'onelogin')
        }).catch((error) => {
            console.log("error: ", error);
        });
    }
    render(){
        return (
            <div className="container is-fluid fullheight has-background-light d-flex align-items-center justify-content-center" >
                <Loading />
            </div>    
        );
    }
}
export default consumeApp(OidcCallbackPage);