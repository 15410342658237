const ENDPOINTS_PATHS = {
    "/sessions": "/api/v1/sessions",

    "/providers/:provider_id": "/api/v1/providers/:provider_id",
    "/providers/:provider_id/sessions": "/api/v1/providers/:provider_id/sessions",
    "/providers": "/api/v1/providers/",

    "/waiting_rooms/:id": "/api/v1/waiting_rooms/:id",
    "/waiting_rooms/:id/sessions": "/api/v1/waiting_rooms/:id/sessions",
    "/waiting_rooms/:room": "/api/v1/waiting_rooms/:room",
    "/waiting_rooms": "/api/v1/waiting_rooms/",
    
    "/sessions/:session_id/join": "/api/v1/sessions/:session_id/join",
    "/patients/checkin": "/api/v1/patients/checkin",
    
    "/users/oidc/callback": "/api/v1/users/oidc/callback",
    "/users/oidc/refresh_token": "/api/v1/users/oidc/refresh_token"
};
export default ENDPOINTS_PATHS;