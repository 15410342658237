import Countly from 'countly-sdk-web';
import { segmentationBase } from './segmentation';

export const countlyAddEvent = (key, segmentation) => {
    const countlyEvent = {
        key: `telehealth.${key}`,
        segmentation: segmentation ? { ...segmentationBase, ...segmentation } : segmentationBase,
    };
    Countly.add_event(countlyEvent);
};
 
export const countlyInit = ({app_key, namespace, url }) => {
    Countly.init({
        app_key: app_key,
        namespace: namespace,
        url: url
    });
    Countly.track_sessions();
    Countly.track_pageview();
    Countly.track_clicks();
    Countly.track_scrolls();
    Countly.track_links();
    Countly.track_forms();
    Countly.collect_from_forms();
    Countly.track_errors();

    return Countly;
}