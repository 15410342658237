import CognitoUser from './User/CognitoUser';
import OneloginUser from './User/OneloginUser';

class User {
    constructor(UserData, type) {
        let user = null;
        switch(type){
            
            case "onelogin":
                user = new OneloginUser(UserData);
            break;

            case "cognito":
            default:
                user = new CognitoUser(UserData);
            break;
        }

        return user;
    }
}
export default User;