import React from 'react';
import Avatar from '../../shared/Avatar';

const VideoAvatar = (props) => {
	let part = props.text.split();

	return (
		<div className="mini-video-preview h100 w100 d-flex justify-content-center align-items-center" >
			<Avatar 
				first_name={part[0]} 
				last_name={part[1]} 
				avatarColor="rgba(122, 129, 139, 0.4)"
			/>
		</div>
	);
}
export default VideoAvatar;