import React from 'react';
import { withRouter } from 'react-router-dom';
import {countlyInit} from './index';
import CountlyConfig from './config';
import ConfigFactory from '../ConfigFactory';

class CountlyTracker extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        const ENV = ConfigFactory.getEnv();
        this.countly = countlyInit({
            ...CountlyConfig[ENV],
            namespace: "telehealth",
        });
    }
    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.countly.track_pageview(this.props.location.pathname);
        }
    }
    render(){
        return (this.props.children);
    }
}
export default withRouter(CountlyTracker);