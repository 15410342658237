window.TELEHEALTH_PERMISSIONS = [];

const Authorizer = {
    setPermissions: (newPermissions) => {
        window.TELEHEALTH_PERMISSIONS = newPermissions;
    },
    hasPermission: (act, obj) => {
        for( let permission of window.TELEHEALTH_PERMISSIONS){
            if(
                (permission[1] === act || Authorizer.keyMatch3(act, permission[1]))
                && (permission[2] === obj || Authorizer.keyMatch3(obj, permission[2])) 
            ){
                return true; 
            }
        }
    
        return false;
    },
    keyMatch3: (key1, key2) => {
        /* 
        determines determines whether key1 matches the pattern of key2 (similar to RESTful path), key2 can contain a *.
        For example, "/foo/bar" matches "/foo/*", "/resource1" matches "/{resource}"
        */
       
        key2 = key2.replace(/\*/g, '.*');
        const regexp = new RegExp(/(.*){[^/]+}(.*)/g);
        for (;;) {
            if (!key2.includes('/{')) {
                break;
            }
            key2 = key2.replace(regexp, '$1[^/]+$2');
        }

        return new RegExp('^' + key2 + '$').test(key1);
    },
    
    // keyMatch4 determines whether key1 matches the pattern of key2 (similar to RESTful path), key2 can contain a *.
    // Besides what keyMatch3 does, keyMatch4 can also match repeated patterns:
    // "/parent/123/child/123" matches "/parent/{id}/child/{id}"
    // "/parent/123/child/456" does not match "/parent/{id}/child/{id}"
    // But keyMatch3 will match both.
    keyMatch4: (key1, key2) => {
        key2 = key2.replace(/\/\*/g, '/.*');
    
        const tokens = [];
        let j = -1;
        for (let i = 0; i < key2.length; i++) {
            const c = key2.charAt(i);
            if (c === '{') {
                j = i;
            } else if (c === '}') {
                tokens.push(key2.substring(j, i + 1));
            }
        }
    
        let regexp = new RegExp(/(.*){[^/]+}(.*)/g);
    
        for (;;) {
            if (!key2.includes('/{')) {
                break;
            }
            key2 = key2.replace(regexp, '$1([^/]+)$2');
        }
    
        regexp = new RegExp('^' + key2 + '$');
    
        let values = regexp.exec(key1);
    
        if (!values) {
            return false;
        }
    
        values = values.slice(1);
    
        if (tokens.length !== values.length) {
            throw new Error('KeyMatch4: number of tokens is not equal to number of values');
        }
    
        const m = new Map();
        tokens.forEach((n, index) => {
            const key = tokens[index];
            let v = m.get(key);
            if (!v) {
                v = [];
            }
        
            if (values) {
                v.push(values[index]);
            }
            m.set(key, v);
        });
    
        for (const value of m.values()) {
            if (value.length > 1) {
                for (let i = 1; i < values.length; i++) {
                    if (values[i] !== values[0]) {
                        return false;
                    }
                }
            }
        }
    
        return true;
    }
}

const CheckPermission = (props) => {
    return Authorizer.hasPermission(props.act, props.obj) ? props.children : null;
};

const {hasPermission, setPermission} = Authorizer;

export {
    hasPermission, 
    setPermission,
    CheckPermission
};
export default Authorizer;