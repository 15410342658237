export default [
	{
		code: 'name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Name cannot be empty',
				rule: ['required'],
			}
		}
	},
	{
		code: 'description',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Description cannot be empty',
				rule: ['required'],
			},
		}
	},
	{
		code: 'slug',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Slug cannot be empty',
				rule: ['required'],
			},
		}
	}
];
