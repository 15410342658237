import React from 'react';
import MiniPublisher from './MiniPublisher';
import MiniSubscriber from './MiniSubscriber';
import './MiniVideos.scss';

class MiniVideos extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    moveAt = (pX, pY) => {
        let el = document.getElementById('mini-videos');

        let position = {};
        position.x = pX - el.offsetWidth / 2;
        position.y = pY - el.offsetHeight / 2;

        el.style.left = position.x + 'px';
        el.style.top = position.y + 'px';
    }
    onMouseMove = (event) => {
        this.moveAt(event.pageX, event.pageY);
    }
    onMouseDown = (event) => {
        let el = document.getElementById('mini-videos');

        document.addEventListener('mousemove', this.onMouseMove);
        el.ondragstart = function () {
            return false;
        };
        el.onmouseup = () => {
            document.removeEventListener('mousemove', this.onMouseMove);
            el.onmouseup = null;
            el.ondragstart = null;
        }
    }
    render(){
        const publisher = this.props.publisher;
        return (
            <div className="mini-videos" id="mini-videos" onMouseDown={this.onMouseDown} >
				<div className="prev"></div>
                {/* <MiniPublisher
                    session={this.props.session}
                    properties={{
                        name: publisher.name,
                        publishAudio: publisher.publishAudio,
                        publishVideo: publisher.publishVideo,
                        facingMode: publisher.facingMode
                    }}
                /> */}

                {publisher.screenShare &&
                    <MiniPublisher
                        session={this.props.session}
                        properties={{
                            name: publisher.name + "'s screen",
                            videoSource: 'screen'
                        }}
                    />
                }

                {this.props.streams.map(stream =>
                    <MiniSubscriber
                        key={stream.id}
                        session={this.props.session}
                        stream={stream}
                        onClick={this.props.onFocus}
                        onChangeAudio={this.props.onChangeAudio}
                    />
                )}
                <div className="next"></div>
			</div>
        );
    }
}
export default MiniVideos