import React from 'react';
import qs from "qs";
import Drawer from "react-drag-drawer";
import HttpClient from '../../shared/utils/HttpClient';
import Loading from "../../shared/Loading";
import Input from '../../shared/Input';
import IconButton from '../../shared/IconButton';
import withValidation from '../../validations/withValidation';
import WaitingRoomsFormRules from '../../validations/FormRules/WaitingRoomsFormRules';
import ProvidersWaitingRoom from './ProvidersWaitingRoom';

class FormWaitingRooms extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            open: true,
            message: null,
            waiting_room: {}
        };
    }
    componentDidMount(){
        if(this.props.mode === 'edit'){
            this.getWaitingRoom(this.props.match.params.id);
        }
    }
    getWaitingRoom(waiting_room_id){
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms/'+waiting_room_id; 

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                waiting_room: data
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    createWaitingRoom(){
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms';
        const {waiting_room} = this.state;
        let postData = {
            name: waiting_room.name,
            description: waiting_room.description,
            slug: waiting_room.slug,
        };
        if (!this.props.validate(postData)) return;
        this.setState({
            loading: true
        });
        HttpClient().post(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false,
                waiting_room: data
            });
            this.props.onCreate(data);
            this.handleClose();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    updateWaitingRoom(waiting_room_id){
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms/'+waiting_room_id;
        const {waiting_room} = this.state;
        let postData = {
            name: waiting_room.name,
            description: waiting_room.description,
            slug: waiting_room.slug,
        };
        if (!this.props.validate(postData)) return;
        this.setState({
            loading: true
        });
        HttpClient().put(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false,
                waiting_room: {
                    ...waiting_room,
                    ...postData
                }
            });
            this.props.onUpdate(waiting_room_id, postData);
            this.handleClose();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    handleClose = () => {
		this.setState({open: false});
		setTimeout(()=>{
			let queryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
			this.props.history.push({
				pathname: '/waiting_rooms',
				search: '?'+qs.stringify(queryParams),
			});
		}, 300);
    }
    handleSubmit = (event) => {
        event.preventDefault();

        if(this.props.mode === 'new'){
            this.createWaitingRoom();
        }else{
            this.updateWaitingRoom(this.props.match.params.id);
        }
    }
    handleChange = (event) => {
        this.setState({
            waiting_room:{
                ...this.state.waiting_room,
                [`${event.target.name}`]: event.target.value
            }
        });
        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state.waiting_room,
            [`${event.target.name}`]: event.target.value
        });
    }
    render(){
        
        const waiting_room = this.state.waiting_room;
        
        return (
            <Drawer
				open={this.state.open}
				onRequestClose={this.handleClose}
				modalElementClass={'drawer-sidebar drawer-md'}
				direction='right'
			>
                {this.state.loading ?
                    <Loading />
                :<div className="py-5 px-5">
                    <div className="d-flex justify-content-between">
                        <h1 className="subtitle">{this.props.mode === 'new' ? 'Create' : 'Edit' } waiting room</h1>
                        <IconButton 
                            type="fa fa-times" 
                            color="danger" 
                            className="pull-right" 
                            onClick={this.handleClose}
                        />
                    </div>
                    <form id='waiting_room-form' method='POST' onSubmit={this.handleSubmit} >
                        {this.state.message &&
                        <article className={this.state.messageType}>
                            <div className='message-body'>
                                {this.state.message}
                            </div>
                        </article>}
                        
                        <Input 
                            label='Name' 
                            name='name' 
                            value={waiting_room.name} 
                            onChange={this.handleChange} 
                            validation={this.props.validations.name}
                        />

                        <Input 
                            label='Description' 
                            name='description' 
                            value={waiting_room.description} 
                            onChange={this.handleChange} 
                            validation={this.props.validations.description} 
                        />

                        <Input 
                            label='Slug' 
                            name='slug' 
                            value={waiting_room.slug} 
                            onChange={this.handleChange} 
                            validation={this.props.validations.slug} 
                        />

                        <div className="level" >
                            <div className='level-item'>
                                <div className='field'>
                                    <div className='control'>
                                        <button className={`button is-primary is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='level-item'>
                                <div className='field'>
                                    <div className='control'>
                                        <button className={`button is-danger is-fullwidth`} type='button' onClick={this.handleClose} >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {this.props.mode === 'edit' &&
                            <ProvidersWaitingRoom 
                                waiting_room_id={this.props.match.params.id} 
                            />
                        }

                    </form>
                </div>}
            </Drawer>
        );
    }
}
export default withValidation(FormWaitingRooms, WaitingRoomsFormRules);