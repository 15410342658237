import React from 'react';
import PropTypes from 'prop-types';
import MutedParticipants from '../Room/MutedParticipants';
import VirtualRoom from '../Room/VirtualRoom';
import MiniPublisher from '../Room/MiniPublisher';
import withMessages from '../../shared/Messages/withMessages';
import withCallSession from '../Room/withCallSession';
import '../Room/CallControls.scss';

import SettingsModal from '../Room/SettingsModal';
import { isMobile } from '../../shared/utils/Basics';
import SettingsBtn from '../Room/controls/SettingsBtn';
import LeaveBtn from '../Room/controls/LeaveBtn';
import VideoBtn from '../Room/controls/VideoBtn';
import MicrophoneBtn from '../Room/controls/MicrophoneBtn';
import ScreenShareBtn from '../Room/controls/ScreenShareBtn';
import PipBtn from '../Room/controls/PipBtn';
import FlipCameraBtn from '../Room/controls/FlipCameraBtn';
import AudioLevelProvider from '../Room/providers/AudioLevelProvider';

class ProviderCallContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenSettings: false,
        };
    }
    componentWillUnmount() {
        this.props.dismissByTag('mic-muted');
    }
    handleAudio = () => {
        const { callSession } = this.props;
        this.props.dismissByTag('mic-muted');
        if (callSession.audio) {
            this.props.showMessage({
                message: <span><span className={`ic-white-microphone-off`} /> You're now muted</span>,
                tag: 'mic-muted'
            });
        }

        callSession.handleAudio();
    }
    toggleSettings = () => {
        this.setState({ isOpenSettings: !this.state.isOpenSettings });
    }
    render() {
        const { callSession } = this.props;
        return (
            <div className="room">
                <div className={`controls is-relative`} >
                    <AudioLevelProvider>
                        <VirtualRoom publisherName={this.props.publisherName} callSession={callSession} />

                        <MutedParticipants mutedParticipants={callSession.mutedParticipants} />


                        <SettingsBtn onClick={this.toggleSettings} />
                        <SettingsModal
                            isOpen={this.state.isOpenSettings}
                            onClose={this.toggleSettings}
                            publisherName={this.props.publisherName}
                            callSession={callSession}
                        />

                        <LeaveBtn onLeaveClassName={"is-inverted"} onClick={this.props.onLeave} />
                        <MiniPublisher
                            session={callSession.session}
                            audioSource={callSession.audioSource}
                            properties={{
                                name: this.props.publisherName,
                                publishAudio: callSession.audio,
                                publishVideo: callSession.video,
                                facingMode: callSession.facingMode
                            }}
                        />
                        <div className="controls-bottom" >
                            <div id="control-bar" className={`d-flex align-items-end`}>
                                <VideoBtn onClick={callSession.handleVideo} isOn={callSession.video} />
                                <MicrophoneBtn onClick={this.handleAudio} isOn={callSession.audio} />
                                {isMobile() && <FlipCameraBtn onClick={callSession.handleFlipCamera} />}
                                {!isMobile() &&
                                    <ScreenShareBtn
                                        onClick={callSession.handleScreenShare}
                                        isOn={callSession.screenShare}
                                    />
                                }
                                <PipBtn focusedVideoElement={callSession.focusedVideoElement} />
                            </div>
                        </div>
                    </AudioLevelProvider>
                </div>
            </div>
        );
    }
}
ProviderCallContainer.proptTypes = {
    publisherName: PropTypes.string,
    apiKey: PropTypes.string,
    sessionId: PropTypes.string,
    token: PropTypes.string,
    onLeave: PropTypes.func,
    onNoParticipants: PropTypes.func,
}
export default withMessages(withCallSession(ProviderCallContainer));
