import React from 'react';
import PropTypes from 'prop-types';
import withAudioLevel from '../providers/withAudioLevel';
import './MicrophoneVolume.scss';

const MicrophoneVolume = (props) => {
    return (
        <div className={`microphone-with-volume ${props.sm && 'is-sm'}`}>
            {props.sm ?
                <span className={`ic-white-microphone-${props.isOn ? 'on' : 'off'}`} />
                : <span className={`ic-microphone-${props.isOn ? 'on' : 'off'}`} />
            }
            {props.isOn && 
                <span className="volume-container">
                    <span className="volume-level" style={{height: props.audioLevel+'%'}} />
                </span>
            }
        </div>
    );
}
MicrophoneVolume.propTypes = {
    audioLevel: PropTypes.number,
    isOn: PropTypes.bool
};
export default withAudioLevel(MicrophoneVolume);