import React from "react";
import { Auth } from "aws-amplify";
import AppContext from "./AppContext";
import OneloginAuth from '../OneloginAuth';
import { getUserClientInfo } from "../shared/utils/webclient";
import User from "../shared/utils/User";
import ONELOGIN_CONFIG from '../config/onelogin';
import ConfigFactory from '../ConfigFactory';

class AppProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            authUser: null,
            checkingAuthentication: true,
            webClientInfo: {},
            isOpenInactivityAlert: false
        };
    }
    getWebClientInfo = () => {
        getUserClientInfo((webClientInfo) => {
            this.setState({ webClientInfo });
        });
    }
    checkCurrentUser = () => {
        if(OneloginAuth.getAuthData()){
            this.checkCurrentUserOnelogin();
        }else{
            this.checkCurrentUserCognito();
        }
    }
    checkCurrentUserCognito = () => {
        this.setState({ checkingAuthentication: true });
        Auth.currentAuthenticatedUser({
            bypassCache: true
        })
        .then((user) => {
            this.login(user, 'cognito');
        })
        .catch((error) => {
            this.setState({
                isLoggedIn: false,
                checkingAuthentication: false
            });
        });
    }
    checkCurrentUserOnelogin = () => {
        this.setState({ checkingAuthentication: true });
        if(OneloginAuth.isValidAuthData()){
            let authData = OneloginAuth.getAuthData();
            if(authData){
                this.login(authData.userData, 'onelogin');
            }else{
                this.setState({
                    isLoggedIn: false,
                    checkingAuthentication: false
                });
            }
        }else{
            this.setState({
                isLoggedIn: false,
                checkingAuthentication: false
            });
        }
    }
    login = (user, type='onelogin') => {
        let authUser = new User(user, type);
        this.setState({
            isLoggedIn: true,
            authUser: authUser,
            checkingAuthentication: false
        });
    }
    updateAuthUser = (data) => {
        const {originalUser, type} = this.state.authUser;
        let authUser = new User(originalUser, type);
        for (let key in data) {
            authUser[key] = data[key];
        }

        this.setState({
            authUser: authUser,
        });
    }
    logout = () => {
        switch(this.state.authUser.type){
            case "onelogin":
                this.oneloginLogout();
            break;
            
            case "cognito":
            default:
                this.cognitoLogout();
            break;
        }
    }
    oneloginLogout = () => {
        OneloginAuth.signOut();
        const ENV = ConfigFactory.getEnv();
        const {ONELOGIN_HOST} = ONELOGIN_CONFIG[ENV];
        window.location.assign(ONELOGIN_HOST);
    }
    cognitoLogout = () => {
        Auth.signOut()
        .then((data) => {
            this.setState({
                isLoggedIn: false,
                authUser: null,
                isOpenInactivityAlert: false
            });
        })
    }
    toggleInactivityAlert = () => {
        this.setState({
            isOpenInactivityAlert: !this.state.isOpenInactivityAlert
        });
    }
    render() {
        return (
            <AppContext.Provider
                value={{
                    isLoggedIn: this.state.isLoggedIn,
                    authUser: this.state.authUser,
                    checkingAuthentication: this.state.checkingAuthentication,
                    webClientInfo: this.state.webClientInfo,
                    updateAuthUser: this.updateAuthUser,
                    login: this.login,
                    logout: this.logout,
                    checkCurrentUser: this.checkCurrentUser,
                    getWebClientInfo: this.getWebClientInfo,
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
export default AppProvider;