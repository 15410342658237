import Authorizer from './../Authorizer';

class CognitoUser {
    constructor(CognitoUser) {
        this.type = "cognito";
        this.originalUser = CognitoUser;
    }
    get sub() {
        return this.originalUser.attributes.sub;
    }
    get attributes() {
        return this.originalUser.attributes;
    }
    get username() {
        return this.originalUser.username;
    }
    set username(value) {

    }
    set policies(newPermissions) {
        Authorizer.setPermissions(newPermissions);
    }
    get challengeName() {
        return this.originalUser.challengeName;
    }
    get fullName() {
        if (this.originalUser.attributes["custom:credential"]) {
            return this.originalUser.attributes.given_name + " "
                + this.originalUser.attributes.family_name + ", "
                + this.originalUser.attributes["custom:credential"];
        }
        return this.originalUser.attributes.given_name + " " + this.originalUser.attributes.family_name;
    }
}
export default CognitoUser;