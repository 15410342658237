export default {
    local: {
        ONELOGIN_HOST: "https://villagemd-dev.onelogin.com",
        ONELOGIN_OIDC_CLIENT_ID: "fa1f4830-fc51-0138-51dc-0213ed617bb3156252",
        ONELOGIN_REDIRECT_URI: "http://localhost:3001/oidc/callback"
    },
    development: {
        ONELOGIN_HOST: "https://villagemd-dev.onelogin.com",
        ONELOGIN_OIDC_CLIENT_ID: "fa1f4830-fc51-0138-51dc-0213ed617bb3156252",
        ONELOGIN_REDIRECT_URI: "http://localhost:3001/oidc/callback"
    },
    test: {
        ONELOGIN_HOST: "https://villagemd-dev.onelogin.com",
        ONELOGIN_OIDC_CLIENT_ID: "fa1f4830-fc51-0138-51dc-0213ed617bb3156252",
        ONELOGIN_REDIRECT_URI: "http://telehealth.docos.me/oidc/callback"
    },
    uat: {
        ONELOGIN_HOST: "https://villagemd.onelogin.com",
        ONELOGIN_OIDC_CLIENT_ID: "2840b330-f526-0138-8ba5-067c03dc70d5100286",
        ONELOGIN_REDIRECT_URI: "https://localhost:3001/oidc/callback"
    },
    production: {
        ONELOGIN_HOST: "https://villagemd.onelogin.com",
        ONELOGIN_OIDC_CLIENT_ID: "f2781df0-0ce4-0139-048f-0ae00689599d100286",
        ONELOGIN_REDIRECT_URI: "http://telehealth.villageplus.com/oidc/callback"
    }
};