import React from 'react';
import RoomContext from './RoomContext';

function withClinicalRoom(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <RoomContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </RoomContext.Consumer>
            );
        }
    };
}
export default withClinicalRoom;