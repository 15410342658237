import React from 'react';
import './avatar.scss'
const Avatar = (props) => {

    const { first_name, last_name, img } = props;
    let avatarText = first_name.charAt(0) + (last_name ? last_name.charAt(0) : first_name.charAt(1));
    avatarText = avatarText.toUpperCase();
    let backgroundColor = props.avatarColor;

    return (
        <div className="avatar d-flex align-items-center justify-content-center" style={{ backgroundColor: backgroundColor }}>
            {img ?
                <img src={img} alt="avatarText" />
                : <div className="avatar-text">
                    {avatarText}
                </div>
            }
        </div>
    );
}
export default Avatar;