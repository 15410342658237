import React from "react";
import AudioLevelContext from "./AudioLevelContext";

class AudioLevelProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            audioLevel: 0,
        };
    }
    setAudioLevel = (volume) => {
        this.setState({
            audioLevel: volume > 0 ? Math.round(volume*100)+10 : 0
        });
    }
    render() {
        return (
            <AudioLevelContext.Provider
                value={{
                    audioLevel: this.state.audioLevel,
                    setAudioLevel: this.setAudioLevel
                }}
            >
                {this.props.children}
            </AudioLevelContext.Provider>
        );
    }
}
export default AudioLevelProvider;