import React from 'react';
import qs from "qs";
import Drawer from "react-drag-drawer";
import HttpClient from '../../shared/utils/HttpClient';
import Loading from "../../shared/Loading";
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import IconButton from '../../shared/IconButton';
import withValidation from '../../validations/withValidation';
import ProvidersFormRules from '../../validations/FormRules/ProvidersFormRules';

class FormProviders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            open: true,
            message: null,
            provider: {}
        };
    }
    componentDidMount() {
        if (this.props.mode === 'edit') {
            this.getProvider(this.props.match.params.id);
        }
    }
    getProvider(provider_id) {
        let apiName = 'telehealthApi';
        let path = '/providers/' + provider_id;

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    loading: false,
                    provider: data
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    getProviderRoleId = (provider) => {
        const defaultRoleId = this.getRoles().find((role) => role.code === 'provider').value;

        return provider.role_id ? provider.role_id 
            : provider.roles ? provider.roles[0].id 
            : defaultRoleId;
    }
    getRoles = () => {
        return [
            {
                value: 2,
                code: 'admin',
                label: "Admin"
            },
            {
                value: 3,
                code: 'provider',
                label: "Provider"
            },
        ]
    }
    createProvider() {
        let apiName = 'telehealthApi';
        let path = '/providers';
        const { provider } = this.state;
        let postData = {
            first_name: provider.first_name,
            last_name: provider.last_name,
            email: provider.email,
            phone_number: provider.phone_number,
            external_id: provider.external_id,
            role_ids: [this.getProviderRoleId(provider)],
        };
        if (!this.props.validate(postData)) return;
        this.setState({
            loading: true
        });
        HttpClient().post(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false,
                    provider: data
                });
                this.props.onCreate(data);
                this.handleClose();
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    updateProvider(provider_id) {
        let apiName = 'telehealthApi';
        let path = '/providers/' + provider_id;
        const { provider } = this.state;
        let postData = {
            first_name: provider.first_name,
            last_name: provider.last_name,
            email: provider.email,
            phone_number: provider.phone_number,
            external_id: provider.external_id,
            role_ids: [this.getProviderRoleId(provider)],
        };
        if (!this.props.validate(postData)) return;
        this.setState({
            loading: true
        });
        HttpClient().put(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false,
                    provider: {
                        ...provider,
                        ...postData
                    }
                });
                this.props.onUpdate(provider_id, postData);
                this.handleClose();
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleClose = () => {
        this.setState({ open: false });
        setTimeout(() => {
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.props.history.push({
                pathname: '/providers',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.mode === 'new') {
            this.createProvider();
        } else {
            this.updateProvider(this.props.match.params.id);
        }
    }
    handleChangeSelect = (name, value) => {
        this.setState({
            provider: {
                ...this.state.provider,
                [name]: value
            }
        });
    }
    handleChange = (event) => {
        this.setState({
            provider: {
                ...this.state.provider,
                [event.target.name]: event.target.value
            }
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state.provider,
            [`${event.target.name}`]: event.target.value
        });
    }
    handlePhoneNumberChange = (event) => {
        if (event.target.value.substring(0, 2) !== '+1') {
            event.target.value = '+1' + event.target.value;
        }

        this.setState({
            provider: {
                ...this.state.provider,
                [`${event.target.name}`]: event.target.value
            }
        });

        //Trigger validation on first input.
        this.props.validate({
            ...this.state.provider,
            [`${event.target.name}`]: event.target.value
        });
    }
    render() {

        const provider = this.state.provider;

        return (
            <Drawer
                open={this.state.open}
                onRequestClose={this.handleClose}
                modalElementClass={'drawer-sidebar drawer-sm'}
                direction='right'
            >
                {this.state.loading ?
                    <Loading />
                    : <div className="py-5 px-5">
                        <div className="d-flex justify-content-between">
                            <h1 className="subtitle">{this.props.mode === 'new' ? 'Create' : 'Edit'} provider</h1>
                            <IconButton
                                type="fa fa-times"
                                color="danger"
                                className="pull-right"
                                onClick={this.handleClose}
                            />
                        </div>
                        <form id='provider-form' method='POST' onSubmit={this.handleSubmit} >
                            {this.state.message &&
                                <article className={this.state.messageType}>
                                    <div className='message-body'>
                                        {this.state.message}
                                    </div>
                                </article>}

                            <Input
                                label='First name'
                                name='first_name'
                                value={provider.first_name}
                                onChange={this.handleChange}
                                validation={this.props.validations.first_name}
                            />

                            <Input
                                label='Last name'
                                name='last_name'
                                value={provider.last_name}
                                onChange={this.handleChange}
                                validation={this.props.validations.last_name}
                            />

                            <Input
                                label='E-mail'
                                name='email'
                                value={provider.email}
                                onChange={this.handleChange}
                                validation={this.props.validations.email}
                            />

                            <Input
                                label='Phone'
                                name='phone_number'
                                value={provider.phone_number}
                                onChange={this.handlePhoneNumberChange}
                                placeholder="(312) 465-7900"
                                validation={this.props.validations.phone_number}
                            />
                            <Input
                                label='OneLoginID'
                                name='external_id'
                                value={provider.external_id}
                                onChange={this.handleChange}
                            />
                            <Select
                                placeholder={"Choose role"}
                                name="role_id"
                                label="Role"
                                options={this.getRoles()}
                                className="is-fullwidth"
                                value={this.getProviderRoleId(provider)}
                                onChange={this.handleChangeSelect}
                            />

                            <div className='field'>
                                <div className='control'>
                                    <button className={`button is-primary is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                        Save
                                </button>
                                </div>
                            </div>

                            <div className='field'>
                                <div className='control'>
                                    <button className={`button is-danger is-fullwidth`} type='button' onClick={this.handleClose} >
                                        Cancel
                                </button>
                                </div>
                            </div>

                        </form>
                    </div>}
            </Drawer>
        );
    }
}
export default withValidation(FormProviders, ProvidersFormRules);