import React from 'react';
import Countly from 'countly-sdk-web';
import Avatar from '../../shared/Avatar';
import PropTypes from 'prop-types';
import AVATAR_COLOR_STATUS from '../../shared/constants/AVATAR_COLOR_STATUS';
import './WaitingRoom.scss';

class WaitingRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        Countly.track_pageview("waitingRoom");
    }
    render() {
        
        const provider = this.props.provider;

        return (
            <div id="focused-video" className="focused-video">
                <div className="has-text-centered">
                    <p className="has-text-gray-90 has-text-weight-medium is-size-5 mt-5">
                        Welcome, {this.props.firstName}!
                    </p>
                    <p className="has-text-gray-70 has-text-centered is-size-6 mb-5" >
                        Your call will start soon
                    </p>
                    <div className="container d-flex align-items-center justify-content-center">
                        <Avatar
                            first_name={provider.first_name}
                            last_name={provider.last_name}
                            avatarColor={AVATAR_COLOR_STATUS[provider.presence]}
                        />
                    </div>
                    <div className="has-text-gray-80 has-text-weight-bold is-size-6 my-4">
                        Dr. {provider.first_name} {provider.last_name}, MD
                    </div>
                    {provider.presence === "offline" ?
                            null
                        : provider.presence === "available" ?
                            <div className="green-pill has-text-weight-medium">Will be with you shortly</div>
                        : <div className="purple-pill has-text-weight-medium">Finishing up with another patient</div>
                    }
                </div>
            </div>
        );
    }
}
WaitingRoom.proptTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    provider: PropTypes.object,
}
export default WaitingRoom;
