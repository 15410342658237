import React from "react";
import RoomContext from "./RoomContext";
import Websocket from '../../shared/Websocket';
import HttpClient from '../../shared/utils/HttpClient';
import consumeApp from '../consumeApp';

const avatarColorByStatus = {
    "in-call": "#FBDC47",
    "available": "#31AD6D",
    "offline": "#ABB1B9",
}

class ClinicalRoomProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            waiting_room: null,
            sessions: [],
            websocket: null
        };
    }
    componentDidMount() {
        this.getMe();
    }
    getMe = () => {
        let apiName = 'telehealthApi';
        let path = '/providers/me';
        HttpClient().get(apiName, path)
            .then((data) => {
                this.props.updateAuthUser(data);
                this.getSessions(data.id);
                this.setState({
                    // TODO: allow provider to change waiting room
                    waiting_room: data.waiting_rooms.length > 0 ? data.waiting_rooms[0] : null,
                });
            });
    }
    getSessions = (provider_id) => {
        let apiName = 'telehealthApi';
        // TODO
        // Maybe this request should be transformed into:
        // let path = `/providers/me/sessions`;
        let path = `/providers/${provider_id}/sessions`;

        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    sessions: data.map((session) => {
                        return {
                            avatarColor: avatarColorByStatus["available"],
                            ...session
                        };
                    })
                });
                this.setupSocket();
            });
    }
    setupSocket = async () => {
        this.socket = new Websocket();
        const userId = this.props.authUser.id;
        await this.socket.iniSocket(userId);
        this.handleClinicianGoOnline();

        this.socket.listen('patient_checked_in', (message) => {
            var newSession = message.data.patient_session_details;
            newSession.avatarColor = avatarColorByStatus["available"];
            this.setState({
                sessions: [...this.state.sessions, newSession]
            });
        });

        this.socket.listen('patient_exited', (message) => {
            var patientId = message.data.patient_id;
            this.state.sessions = this.state.sessions.filter((el) => el.patient_id !== patientId);
            this.setState({
                sessions: this.state.sessions
            })
        });

        if (this._isUnmounted) {
            this.socket.close();
        }
    }
    getListOfPatientIds = () => {
        let patientIds = this.state.sessions.map((session) => {
            return session.patient_id;
        })
        return patientIds
    }
    handleClinicianGoOnline = () => {
        let patientIds = this.getListOfPatientIds();
        let message = { "action": "clinician_online", "data": { "provider_id": this.props.authUser.id, "patient_ids": patientIds } };
        this.socket.send(message);
    }
    handleClinicianGoOffline = () => {
        let patientIds = this.getListOfPatientIds();
        let message = { "action": "clinician_offline", "data": { "provider_id": this.props.authUser.id, "patient_ids": patientIds } };
        this.socket.send(message);

        this.closeSocket();
    }
    closeSocket = () => {
        this._isUnmounted = true;
        if (this.socket) {
            this.socket.close();
        }
    }
    render() {
        return (
            <RoomContext.Provider
                value={{
                    waiting_room: this.state.waiting_room,
                    sessions: this.state.sessions,
                    websocket: this.socket,
                    handleClinicianGoOffline: this.handleClinicianGoOffline
                }}
            >
                {this.props.children}
            </RoomContext.Provider>
        );
    }
}
export default consumeApp(ClinicalRoomProvider);