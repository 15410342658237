import React from 'react';
import PropTypes from 'prop-types';
import './ControlBtn.scss';
import {countlyAddEvent} from '../../../countly';

const SettingsBtn = (props) => {
    const handleClick = (event) => {
        countlyAddEvent("clickSettingsGear")
        if(props.onClick) props.onClick(event);
    }
    return (
        <div id="btn-settings" className="call-control is-sm" onClick={handleClick}>
            <div className="ctr-btn" >
                <span className="ic-settings" />
            </div>
        </div>
    );
}
SettingsBtn.propTypes = {
    onClick: PropTypes.func,
};
export default SettingsBtn;
