import React from 'react';
import PropTypes from 'prop-types';
import WaitingRoom from './WaitingRoom';
import VirtualRoom from '../Room/VirtualRoom';
import MiniPublisher from '../Room/MiniPublisher';
import MutedParticipants from '../Room/MutedParticipants';
import withMessages from '../../shared/Messages/withMessages';
import withCallSession from '../Room/withCallSession';
import '../Room/CallControls.scss';

import SettingsModal from '../Room/SettingsModal';
import {isMobile} from '../../shared/utils/Basics';
import SettingsBtn from '../Room/controls/SettingsBtn';
import LeaveBtn from '../Room/controls/LeaveBtn';
import VideoBtn from '../Room/controls/VideoBtn';
import MicrophoneBtn from '../Room/controls/MicrophoneBtn';
import FlipCameraBtn from '../Room/controls/FlipCameraBtn';
import AudioLevelProvider from '../Room/providers/AudioLevelProvider';

class PatientCallContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenSettings: false
        };
    }
    componentWillUnmount() {
        this.props.dismissByTag('mic-muted');
    }
    handleAudio = () => {
        const {callSession} = this.props;
        this.props.dismissByTag('mic-muted');
        if(callSession.audio){
            this.props.showMessage({
                message: <span><span className={`ic-white-microphone-off`} /> You're now muted</span>,
                tag: 'mic-muted'
            });
        }

        callSession.handleAudio();
    }
    toggleSettings = () => {
        this.setState({isOpenSettings: !this.state.isOpenSettings});
    }
    render(){
        const {callSession} = this.props;
        const publisherName = this.props.firstName + ' ' + this.props.lastName;

        return (
            <div className={callSession.streams.length > 0 ? "room" : "waiting-room"}>
                <div className={`controls is-relative ${callSession.streams.length < 1 && 'move-video-down'}`} >
                    <AudioLevelProvider>
                        {callSession.streams.length > 0 ?
                            <VirtualRoom publisherName={publisherName} callSession={callSession} />
                        :
                            <WaitingRoom
                                firstName={this.props.firstName}
                                lastName={this.props.lastName}
                                provider={this.props.provider}
                            />
                        }

                        <MutedParticipants mutedParticipants={callSession.mutedParticipants} />

                        <SettingsBtn onClick={this.toggleSettings} />
                        <SettingsModal
                            isOpen={this.state.isOpenSettings}
                            onClose={this.toggleSettings}
                            publisherName={publisherName}
                            callSession={callSession}
                        />

                        <LeaveBtn
                            onLeaveClassName={callSession.streams.length > 0 ? "is-inverted" : "is-outlined"}
                            onClick={this.props.onLeave}
                        />
                        <MiniPublisher
                            session={callSession.session}
                            audioSource={callSession.audioSource}
                            properties={{
                                name: publisherName,
                                publishAudio: callSession.audio,
                                publishVideo: callSession.video,
                                facingMode: callSession.facingMode
                            }}
                        />
                        <div className="controls-bottom" >
                            <div id="control-bar" className={`d-flex align-items-end`}>
                                <VideoBtn onClick={callSession.handleVideo} isOn={callSession.video} />
                                <MicrophoneBtn onClick={this.handleAudio} isOn={callSession.audio} />
                                {callSession.streams.length > 0 && isMobile() &&
                                    <FlipCameraBtn onClick={callSession.handleFlipCamera} />
                                }
                            </div>
                        </div>
                    </AudioLevelProvider>
                </div>
            </div>
        );
    }
}
PatientCallContainer.proptTypes = {
    apiKey: PropTypes.string,
    sessionId: PropTypes.string,
    token: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    provider: PropTypes.object,
    onLeave: PropTypes.func,
    onNoParticipants: PropTypes.func,
}
export default withMessages(withCallSession(PatientCallContainer));
