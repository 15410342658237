import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Input from './Input';
import {elementPosition, isDescendant} from './utils/Basics.js';
import uuid4 from 'uuid4';
import KeyChar from './utils/KeyChar'
import './select2.scss';

/*
    on window.resize
    on window.scroll
    calculate repositioning
*/

class Select extends React.Component {
    constructor(props){
        super(props);
        this.state={
            search:"",
            isOpen: false,
            activeOption:-1,
        };
        this.INPUT_ID = "tc-select-"+uuid4();
        this.hideDropdown = this.hideDropdown.bind(this);
    }
    handleMoveSelection = (e) => {
        let filteredOptions = this.filterOptions(this.props.options);
        if(KeyChar(e).is('ESC')){ // ESC
            this.hideDropdown(e, true);
        }else if(KeyChar(e).is('ARROW_DOWN')){ // down
            if(this.state.activeOption < filteredOptions.length-1){
                this.setState({activeOption: this.state.activeOption+1});
            }else{
                this.setState({activeOption: 0});
            }
            this.showDropdown(e);
        }else if(KeyChar(e).is('ARROW_UP')){ //up
            if(this.state.activeOption > 0){
                this.setState({activeOption: this.state.activeOption-1});
            }else{
                this.setState({activeOption: filteredOptions.length-1});
            }
            this.showDropdown(e);
        }else if(KeyChar(e).is('ENTER')){ // ENTER
            if(this.state.activeOption > -1 && this.state.activeOption < filteredOptions.length){
                e.preventDefault();
                this.selectOption(filteredOptions[this.state.activeOption])();
            }
        }
    }
    handleChangeSearch = (event) => {
        let searchText = event.target.value;
        this.setState({search: searchText});
    }
    getLabel = (value)=>{
        let label = "";
        this.props.options.map((option) => {
            if(option.value === value){
                label = option.label;
            }

            return option;
        });
        return label;
    }
    showDropdown = (event) => {
        if(!this.state.isOpen){
            this.setState({isOpen: true});
            document.body.addEventListener('click', this.hideDropdown);
        }
    }
    hideDropdown(event, forcedClose = false){
        if(!forcedClose 
            && (
                isDescendant(document.getElementById(this.INPUT_ID+"-container"), event.target)
                || isDescendant(document.getElementById(this.INPUT_ID+"-dropdown"), event.target)
            )
        ){
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        if(this.state.isOpen){
            document.body.removeEventListener('click', this.hideDropdown);
            this.setState({isOpen: false});
        }
    }
    selectOption = (option) => event => {
        this.props.onChange(this.props.name, option);
        if(this.state.isOpen){
            document.body.removeEventListener('click', this.hideDropdown);
            this.setState({isOpen: false, search:""});
        }
    }
    clearSelection = (event) => {
        this.props.onChange(this.props.name, undefined);
        this.showDropdown(event);
        setTimeout(() => {
            document.getElementById(this.INPUT_ID+"-input").focus();
        }, 200);
    }
    filterOptions = (options) => {
        return options.filter((option) => option.label.toLowerCase().includes(this.state.search.toLowerCase()) );
    }
    render(){

        let className= this.props.className ? "tc-select "+this.props.className : "tc-select";

        return(
            <div id={this.INPUT_ID+"-container"} className={className} >
                {this.props.value ?
                <React.Fragment>
                    {this.props.label && <label className="tc-select-label">{this.props.label}</label>}
                    <div 
                        className="tc-selected" 
                        onClick={this.showDropdown} 
                    >
                        {this.getLabel(this.props.value)}
                        {this.props.disableClear &&
                        <span 
                            className="tc-select-clear" 
                            onClick={this.clearSelection} 
                            role="button" 
                        >x</span>}
                        <span 
                            className="tc-select-open ikim-arrow-down" 
                            onClick={this.showDropdown} 
                            role="button" 
                        ></span>
                    </div>
                </React.Fragment>
                : <Input 
                    id={this.INPUT_ID+"-input"} 
                    type="text" 
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    value={this.state.search} 
                    name={"search"} 
                    autoComplete={"off"}
					autoCorrect={"off"}
                    onChange={this.handleChangeSearch}
                    onClick={this.showDropdown}
                    rightIcon={{
                        type:"ikim-arrow-down",
                        onClick: this.showDropdown
                    }} 
                    onKeyDown={this.handleMoveSelection}
                />}
                {this.state.isOpen && 
                <SelectDropdown dropdownPosition={this.props.dropdownPosition} >
                    <OptionsContainer 
                        containerId={this.INPUT_ID+"-dropdown"} 
                        selectContainer={this.INPUT_ID+"-container"} 
                        className={this.props.className} 
                        dropdownPosition={this.props.dropdownPosition} 
                    >
                        {this.filterOptions(this.props.options).map((option, i)=>
                        option &&  
                        <Option 
                            onSelect={this.selectOption(option)} 
                            className={this.state.activeOption === i || this.props.value === option.value ? "active":""} 
                        >
                            {this.props.optionComponent ? this.props.optionComponent(option) : option.label}
                        </Option>
                        )}
                    </OptionsContainer>
                </SelectDropdown>}

            </div>
        );
    }
}

Select.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placholder: PropTypes.string,
    options: PropTypes.array,
    disableClear: PropTypes.bool,
    dropdownPosition: PropTypes.string
};

Select.defaultProps = {
    options:[],
    disableClear: false,
    dropdownPosition: "absolute"
};

class SelectDropdown extends React.Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        return(
            this.props.dropdownPosition === "relative" ?
                this.props.children
            : ReactDOM.createPortal(this.props.children, document.body)
        );
    }
}
const OptionsContainer = (props) => {

    let pos = elementPosition(props.selectContainer);
    
    let style = {
        top: pos.top+40+5,
        left: pos.left,
        width: pos.width,
    };

    if(props.dropdownPosition === "relative"){
        style = {
            top: -60,
            left: 0,
            width: pos.width,
        };
    }

    let className= props.className ? "tc-select-dropdown "+props.className : "tc-select-dropdown";
    return(
        <ul id={props.containerId} className={className} style={style} >
            {props.children}
        </ul>
    );
};
const Option = (props) => {
    return(<li role="button" onClick={props.onSelect} className={props.className} >{props.children}</li>);
};
export default Select;