import React from 'react';
import './MutedParticipants.scss';

const MutedParticipants = (props) => {
    return (
        <div className="muted-msg-container">
            {props.mutedParticipants.map( (participant) =>
                <div className="toast-message" key={participant.id}>
                    {participant.name} <span className={`ic-red-microphone-off`} />
                </div>
            )}
        </div>
    );
}
export default MutedParticipants;
