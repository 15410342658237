import React from 'react';
import PropTypes from 'prop-types';
import MicrophoneVolume from './MicrophoneVolume';
import './ControlBtn.scss';

const MicrophoneBtn = (props) => {
    return (
        <div id="ctrl-btn-microphone" className="call-control" onClick={props.onClick} >
            <div className="ctr-btn">
                <MicrophoneVolume isOn={props.isOn} />
            </div>
            <div className="ctr-label">
                {props.isOn ? 'Mute' : 'Unmute'}
            </div>
        </div>
    );
}
MicrophoneBtn.propTypes = {
    onClick: PropTypes.func,
    isOn: PropTypes.bool
};
export default MicrophoneBtn;