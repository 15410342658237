export default [
  {
    code: 'patient_email',
    element_type: 'input',
    rules: {
      required: {
        message: "Patient email is required.",
        rule: ["customRule", (value, data, Rules) => {
          if (data.open_email_modal) {
            return Rules.required(value, data);
          }
          return true;
        }],
      },
      email: {
        type: 'error',
        error_type: 'hard',
        message: 'This is not a valid email.',
        rule: ['email'],
      }
    }
  },
  {
    code: 'patient_phone_number',
    element_type: 'input',
    rules: {
      required: {
        message: "Patient phone number is required.",
        rule: ["customRule", (value, data, Rules) => {
          if (data.open_sms_modal) {
            return Rules.required(value, data);
          }
          return true;
        }],
      },
      phone: {
        type: 'error',
        error_type: 'hard',
        message: 'This is not a valid phone number.',
        rule: ['phone'],
      }
    }
  },
  {
    code: 'patient_consented_to_sms',
    element_type: 'input',
    rules: {
      requiredIsTrue: {
        message: "Patient consent is required prior to sending them text messages.",
        rule: ["customRule", (value, data, Rules) => {
          if (data.open_sms_modal) {
            return Rules.isTrue(value, data);
          }
          return true;
        }],
      },
    }
  },
];
