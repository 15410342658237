export default [
  {
    value: "",
    label: "Invite via"
  },
  {
    value: "open_email_modal",
    label: "Email"
  },
  {
    value: "open_sms_modal",
    label: "Text Message"
  },
]