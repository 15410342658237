import JwtDecode from "jwt-decode";
import HttpClient from './shared/utils/HttpClient';

class OneloginAuth {
    storageKey = "VMD_Authorization";
    storage = sessionStorage;
    redirectLoginUrl = null;
    refreshingToken = false;
    setRedirectLoginUrl(relative_url){
        this.redirectLoginUrl = relative_url;
    }
    setAuthData(authData){
        this.storage.setItem(this.storageKey, JSON.stringify(authData));
    }
    getAuthData(){
        let authData = this.storage.getItem(this.storageKey);
        try {
            authData = JSON.parse(authData);
        } catch (e) {
            authData = null;
        }

        return authData;
    }
    async getAccessToken(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let accessToken = authData.accessToken;
        if(this.isAccessTokenExpired()){
            accessToken = await this.refreshTokens().accessToken;
        }

        return accessToken;
    }
    async getIdToken(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let idToken = authData.idToken;
        if(this.isIdTokenExpired()){
            idToken = await this.refreshTokens().idToken;
        }

        return idToken;
    }
    isJwtTokenExpired(token){
        let payload = JwtDecode(token);
        let currentTimestamp = (new Date()).getTime() / 1000;
        if(currentTimestamp > payload.exp) {
            return true
        }
        
        return false;
    }
    isAccessTokenExpired(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let token = authData.accessToken;
        return token ? this.isJwtTokenExpired(token) : false;
    }
    isRefreshTokenExpired(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let token = authData.refreshToken;
        return token ? this.isJwtTokenExpired(token) : false;
    }
    isIdTokenExpired(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let token = authData.idToken;
        return token ? this.isJwtTokenExpired(token) : false;
    }
    isValidAuthData(){
        //Notes: this should be run at the initial execution otherwise user should be redirected into loginPage
        return !(this.isAccessTokenExpired() && this.isRefreshTokenExpired());
    }
    getRefreshToken(){
        let authData = this.getAuthData();
        authData = authData ? authData : {};
        let token = authData.refreshToken
        return token;
    }
    async refreshTokens(){
        if(this.refreshingToken) return;
        this.refreshingToken = true;
        
        let authData =this.getAuthData();
        let currentAuthData = authData ? authData : {};
        let apiName = 'telehealthApi';
        let path = '/users/oidc/refresh_token';
        let params = {
            refresh_token: this.getRefreshToken(),
        };

        let response = await HttpClient().post(apiName, path, params);
        this.setAuthData({
            ...currentAuthData,
            accessToken: response.accessToken,
            idToken: response.idToken
        });

        return this.getAuthData();
    }
    signOut(){
        this.storage.removeItem(this.storageKey);
    }
}
export default (new OneloginAuth());