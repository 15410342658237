import React from 'react';
import { NavLink } from 'react-router-dom';
import NormalSidebar from '../Sidebar/NormalSidebar';
import {CheckPermission} from '../../../shared/utils/Authorizer';

const ProviderNormalSidebar = (props) => {
    return (
        <NormalSidebar onCollapse={props.onCollapse}>
            <div className={`menu flex-grow-1`}>
                <ul className="menu-list">
                    <li>
                        <NavLink to="/dashboard">
                            <i className="fa fa-tachometer-alt" /> Dashboard
                        </NavLink>
                    </li>
                    <CheckPermission act='GET' obj={'/providers'} >
                        <li>
                            <NavLink to="/providers">
                                <i className="fa fa-users" /> Providers
                            </NavLink>
                        </li>
                    </CheckPermission>
                    <CheckPermission act='GET' obj={'/waiting_rooms'} >
                        <li>
                            <NavLink to="/waiting_rooms">
                                <i className="fas fa-clinic-medical" /> Waiting rooms
                            </NavLink>
                        </li>
                    </CheckPermission>
                </ul>
            </div>
            <div className="px-3 py-3 has-text-left">
                <button className="button is-info is-outlined is-small is-fullwidth" onClick={props.onLogout} >
                    <i className="fas fa-sign-out-alt" /> Logout
                </button>
            </div>
        </NormalSidebar>
    );
}
export default ProviderNormalSidebar;