export default {
	local: {
        app_key: "fdae69ec060c71eb7db7cbd4dc9a0febf144045e",
        url: "https://countly.docos.me"
    },
    development: {
        app_key: "fdae69ec060c71eb7db7cbd4dc9a0febf144045e",
        url: "https://countly.docos.me"
    },
    test: {
        app_key: "2d2608099d02a6e2051d44bce91c00f113701fc0",
        url: "https://countly.docos.me"
    },
    uat: {
        app_key: "",
        url: "https://countly.docos.com"
    },
    production: {
        app_key: "",
        url: "https://countly.docos.com"
    },
};
 