import React from 'react';
import {withRouter} from 'react-router-dom';
import NavLink from "../../core/router/NavLink";
import Loading from "../../shared/Loading";
import InputSearch from "../../shared/InputSearch";
import {CheckPermission} from '../../shared/utils/Authorizer';

class ListProviders extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            search: ""
        };
    }
    handleSearch = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    filterProviders = () => {
        return this.props.providers.filter((provider)=>{
            if(this.state.search === "" || this.state.search === null || this.state.search === undefined) {
                return true;
            }

            return (provider.first_name.toLowerCase()
                + provider.last_name.toLowerCase()
                ).includes(this.state.search.toLowerCase())
        });
    }
    openForm = (providerId) => (event) => {
        if(providerId === 'new')
            return this.props.history.push('/providers/new');
        else
            return this.props.history.push('/providers/edit/'+providerId);
    }
    render(){
        return (
            <div>
                <div className="level">
                    <div className="level-left" >
                        <div className="level-item" >
                            <h1 className="title">Providers</h1>
                        </div>
                        <CheckPermission act='POST' obj={'/providers'} >
                            <div className="level-item" >
                                <button className="button is-primary is-small" onClick={this.openForm('new')} >
                                    <i className="fas fa-plus" /> &nbsp;new
                                </button>
                            </div>
                        </CheckPermission>
                    </div>
                    <div className="level-right" >
                        <div className="level-item" >
                            <InputSearch value={this.state.search} name="search" onChange={this.handleSearch} />
                        </div>
                    </div>
                </div>
                <table className="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.loading ?
                        <tr id="loading-row" >
                            <td colSpan={5} >
                                <Loading />
                            </td>
                        </tr>
                    : this.filterProviders().map((provider) => 
                        <tr key={provider.id}>
                            <td>{provider.first_name}</td>
                            <td>{provider.last_name}</td>
                            <td>
                                <CheckPermission act='DELETE' obj={`/providers/${provider.id}`} >
                                    <span className="pointer has-text-danger is-size-7" onClick={this.props.onRemove(provider)} >
                                        Remove
                                    </span>
                                </CheckPermission>
                                &nbsp;&nbsp;
                                <CheckPermission act='GET' obj={`/providers/${provider.id}`} >
                                    <NavLink to={"/providers/edit/"+provider.id} >
                                        Open <i className="fas fa-chevron-right" />
                                    </NavLink>
                                </CheckPermission>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default withRouter(ListProviders);
