import React from 'react';
import PropTypes from 'prop-types';
import './IconButton.scss';

const IconButton = (props) => {

    let classNames = props.className ? 'icon-button '+props.className : 'icon-button';
    return (
        <div className={classNames} onClick={props.onClick} title={props.title} >
            <div className={'round-shadow round-shadow-'+props.color+' has-text-'+props.color}>
                <div className='circle'>
                    <i className={props.type} />
                </div>
            </div>
        </div>
    );
};
IconButton.defaultProps = {
    color: 'primary'
};
IconButton.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};
export default IconButton;