import { API } from 'aws-amplify';
import HttpClientDecorator from './HttpClient/HttpClientDecorator';
import Endpoints from './HttpClient/Endpoints';
import HttpClientPlugins from './HttpClient/HttpClientPlugins';

function HttpClient(){
    
    this.decorator = new HttpClientDecorator();
    this.max_retries = 0;
    this.attempts_counter = 0;
    this.stopRetries = false;
    this.eventRegistry = {};

    this.useFormat = (format) => {
        this.decorator = new HttpClientDecorator(format);
        return this;
    };
    this.retries = (max_retries = 0 ) => {
        this.max_retries = max_retries;
        return this;
    };
    this.stopRetry = () => {
        this.stopRetries = true;
    }
    this.shouldRetry = () => {
        if(this.stopRetries){
            this.stopRetries = false
            return false;
        }

        if(this.max_retries > this.attempts_counter){
            this.attempts_counter++;
            return true;
        }
        return false;
    };
    this.addHandler = (handleName, handlerFunction) => {
        this[handleName] = handlerFunction;
        return this;
    };
    this.on = (eventType, handler) => {
        this.eventRegistry[eventType] = handler;
    };
    this.dispatch = (eventType, data) => {
        if(this.eventRegistry[eventType]){
            this.eventRegistry[eventType](data);
        }
    }
    this.get = function(apiName, path, queryStringParameters = null, options = {}){
        path = Endpoints.buildPath(path);
        if(queryStringParameters){
            options.queryStringParameters = this.decorator.queryParams(queryStringParameters);
        }
        return (new Promise((resolve, reject) => {
            API.get(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(error.response){
                    this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                        method: "GET", 
                        apiName, 
                        path, 
                        options,
                        response: error.response
                    });
                }
                
                if(this.shouldRetry()){
                    try{
                        await this.get(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
                
            });
        }));
    };
    this.post = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.post(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(error.response){
                    this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                        method: "POST", 
                        apiName, path, 
                        options, 
                        response: error.response
                    });
                }

                if(this.shouldRetry()){
                    try{
                        await this.post(...arguments);
                    }catch(e){
                        error = e;
                    }
                }

                reject(error);
            });
        }));

    };
    this.patch = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.patch(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(error.response){
                    this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                        method: "PATCH", 
                        apiName, 
                        path, 
                        options,
                        response: error.response
                    });
                }

                if(this.shouldRetry()){
                    try{
                        await this.patch(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
    this.put = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.put(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(error.response){
                    this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                        method: "PUT", 
                        apiName, 
                        path, 
                        options,
                        response: error.response
                    });
                }
                
                if(this.shouldRetry()){
                    try{
                        await this.put(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
    this.delete = function(apiName, path, options = {}){
        path = Endpoints.buildPath(path);
        
        return (new Promise((resolve, reject) => {
            API.del(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(error.response){
                    this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                        method: "DELETE", 
                        apiName, 
                        path, options,
                        response: error.response
                    });
                }

                if(this.shouldRetry()){
                    try{
                        await this.put(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
}

function HttpClientFactory(){
    let client = new HttpClient();

    HttpClientPlugins.forEach((plugin) => {
        client = plugin(client);
    });

    return client
}
export default HttpClientFactory;