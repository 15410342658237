import NormalDecorator from './NormalDecorator';
import ODataDecorator from './ODataDecorator';

function HttpClientDecorator(format){
    let decorator;
    switch(format){
        case "odata":
            decorator = new ODataDecorator();
        break;
        case "normal":
        default:
            decorator = new NormalDecorator();
        break;
    }

    return decorator;
}
export default HttpClientDecorator;