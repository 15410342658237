import React from 'react';
import PropTypes from 'prop-types';
import './Select.scss';

class Select extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleChange = (event) => {
        this.props.onChange(this.props.name, event.target.value);
    }
    classNameValidation(){
        if(!this.props.validation) return "";

        switch(this.props.validation.type){
            case "success":
                return "is-success ";
            case "error":
            default:
                return "is-danger";
        }
    }
    classNameLabel(){
        if(!this.props.validation) return "label";

        let labelClass = "";
        switch(this.props.validation.type){
            case "success":
                labelClass = " has-text-success ";
                break;
            case "error":
            default:
                labelClass = " has-text-danger ";
                break;
        }
        
        return "label " + labelClass;
    }
    render(){
        return(
            <div className="field">
                {this.props.label &&
                    <label htmlFor={this.props.name} className={this.classNameLabel()} >
                        {this.props.label}
                    </label>
                }
                <div className={"control select-input select-"+this.props.name}>
                    <div className={"select "+this.props.className+" "+this.classNameValidation()}>
                        <select 
                            placeholder={this.props.placeholder} 
                            onChange={this.handleChange} 
                            value={this.props.value} 
                            name={this.props.name}
                            disabled={this.props.disabled}
                        >
                            {this.props.options.map((option) =>
                                <option key={option.value} value={option.value} >
                                    {option.label}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
                {this.props.validation &&
                    <p className={"help "+this.classNameValidation()}>
                        {this.props.validation.message}
                    </p>
                }
            </div>
        );
    }
}
Select.defaultProps = {
    options: [],
    disabled: false,
};
Select.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};
export default Select;
