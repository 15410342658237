import React from 'react';
import HttpClient from '../../shared/utils/HttpClient';
import IconButton from '../../shared/IconButton';
import Loading from '../../shared/Loading';

class ProviderAssigned extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    handleUnassign = (provider) => (event) => {
        this.unassignProvider(this.props.waiting_room_id, provider.id, () => {
            this.props.onUnassign(provider);
        });
    }
    unassignProvider(waiting_room_id, provider_id, onSuccess = null){
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/unassign_provider`; 
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false
            });
            if(onSuccess) onSuccess();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    render(){
        return(
            <React.Fragment>
                <span>
                    {this.props.provider.first_name} {this.props.provider.last_name} 
                </span>
                {this.state.loading ? 
                    <Loading />
                : <IconButton 
                    type="fa fa-times" 
                    color="danger" 
                    className="pull-right" 
                    onClick={this.handleUnassign(this.props.provider)} 
                />}
            </React.Fragment>
        );
    }
}
export default ProviderAssigned;