import React from 'react';
import PropTypes from 'prop-types';
import './ControlBtn.scss';

const FlipCameraBtn = (props) => {
    return (
        <div className={`call-control`} onClick={props.onClick} >
            <div className="ctr-btn">
                <span className="ic-flip-camera" />
            </div>
            <div className="ctr-label">
                Flip
            </div>
        </div>
    );
}
FlipCameraBtn.propTypes = {
    onClick: PropTypes.func
};
export default FlipCameraBtn;