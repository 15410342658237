import React from 'react';
import RoomSidebar from './partials/RoomSidebar';
import Messages from '../shared/Messages/Messages';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import ConfigFactory from '../ConfigFactory';

class RoomLayout extends React.Component {
	render() {
		return (
			<div className='App fullheight is-fluid d-flex flex-column'>
				<EnvironmentBanner env={ConfigFactory.getEnv()} />
				<div className="d-flex flex-grow-1 flex-row align-items-stretch">
					<RoomSidebar />
					<div className='flex-grow-1 has-background-light'>
						<Messages />
						{this.props.children && this.props.children}
					</div>
				</div>
			</div>
		);
	}
}
export default RoomLayout;