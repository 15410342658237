import React from 'react';
import Countly from 'countly-sdk-web';
class WaitingRoomNotFound extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        Countly.track_pageview("waitingRoomNotFound");
    }
    render(){
        return (
            <div className='App fullheight'>
                <div className='container is-fluid is-paddingless fullheight-body'>
                    <div className='columns is-vcentered is-centered fullheight'>
                        <div className='column is-4'>
                            <div className='box'>
                                <div className="media-content my-4">
                                    <figure>
                                        <img src="../../village-medical-dark-logo-60.png" width="155" height="28" alt="docOS logo" />
                                    </figure>
                                </div>
                                <h2 className="has-text-left has-text-weight-bold is-size-3">Oops!</h2>
                                <p className="has-text-left is-size-5" >
                                    It appears that room doesn't exist
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WaitingRoomNotFound;