import React from 'react';

const BulmaLoader = (props) =>{
    return (
        <div 
            className="loader is-loading" 
            style={{
                width: props.width, 
                height: props.height,
                borderLeftColor: props.color,
                borderBottomColor: props.color,
            }}
        ></div>
    );
}
export default BulmaLoader;