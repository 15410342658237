import React from 'react';
import Loading from '../../shared/Loading';
import qs from 'query-string';
import ONELOGIN_CONFIG from '../../config/onelogin';
import ConfigFactory from '../../ConfigFactory';

class OidcLoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.redirectToOneloginAuthURL();
    }
    redirectToOneloginAuthURL(){
        /*
            Generate the redirect_uri with response_type, client_id, scope, redirect_uri,
        */
        const ENV = ConfigFactory.getEnv();
        const {ONELOGIN_OIDC_CLIENT_ID, ONELOGIN_REDIRECT_URI, ONELOGIN_HOST} = ONELOGIN_CONFIG[ENV];
        
        let params = {
            'response_type': 'code',
            'scope': 'openid profile groups',
            'client_id': ONELOGIN_OIDC_CLIENT_ID,
            'redirect_uri': ONELOGIN_REDIRECT_URI
        }
        const authUrl = `${ONELOGIN_HOST}/oidc/2/auth?${qs.stringify(params)}`
        window.location.assign(authUrl);
    }
    render(){
        return (
            <div className="container is-fluid fullheight has-background-light d-flex align-items-center justify-content-center" >
                <Loading />
            </div>
        );
    }
}
export default OidcLoginPage;