import React from 'react';
import PropTypes from 'prop-types';
import ModalConfirm from '../../../shared/Modal/ModalConfirm';
import {countlyAddEvent} from '../../../countly';

class LeaveBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openConfirmModal: false
        };
    }
    toggleConfirmModal = () => {
        this.setState({
            openConfirmModal: !this.state.openConfirmModal
        }, () => {
            countlyAddEvent(this.state.openConfirmModal ? "clickLeave" : "clickLeaveCancel");
        });
    }
    handleLeaveConfirm = (event) => {
        countlyAddEvent("clickLeaveConfirm");
        this.props.onClick(event)
    }
    render(){
        return (
            <React.Fragment>
                <button
                    id="btn-leave"
                    className={`button is-danger ${this.props.onLeaveClassName}`}
                    onClick={this.toggleConfirmModal}
                >
                    Leave
                </button>
                <ModalConfirm
                    isOpen={this.state.openConfirmModal}
                    title="Leave meeting?"
                    onConfirm={this.handleLeaveConfirm}
                    confirmText="Leave"
                    onCancel={this.toggleConfirmModal}
                    cancelText="Cancel"
                />
            </React.Fragment>
        );
    }
}
LeaveBtn.propTypes = {
    onClick: PropTypes.func,
    onLeaveClassName: PropTypes.string
};
export default LeaveBtn;
