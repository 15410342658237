import React from 'react';
import { OTPublisher } from 'opentok-react';
import withAudioLevel from './providers/withAudioLevel';
import './VideoContainer.scss';

class Publisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            stream: null,
            publisher: null,
        };
        this.otPublisher = React.createRef();
    }
    componentDidMount() {
        this.getPublisher();
    }
    componentDidUpdate(prevProps){
        if(!this.state.publisher){
            this.getPublisher();
        }
        if(this.state.publisher && prevProps.audioSource !== this.props.audioSource){
			this.state.publisher.setAudioSource(this.props.audioSource);
        }
        if(this.state.publisher && prevProps.properties.facingMode !== this.props.properties.facingMode){
			this.state.publisher.cycleVideo();
		}
    }
    getPublisher() {
        if (this.otPublisher) {
            window.testPublisher = this.otPublisher.current.getPublisher();
            this.setState({
                publisher: this.otPublisher.current.getPublisher(),
            });
        }
    }
    onError = (err) => {
        this.setState({ error: `Failed to publish: ${err.message}` });
    }
    render() {

        return (
        <div className="video-container publisher">
            {this.state.error ? <div className="error">{this.state.error}</div> : null}
            
            {/* <div className="video-status-bar level">
                <div className="level-left">
                    {this.props.properties.name} 
                </div>
            </div> */}

            <OTPublisher
                ref={this.otPublisher}
                session={this.props.session}
                className="video"
                eventHandlers={{
                    streamCreated: (StreamEvent) => {
                        this.setState({stream: StreamEvent.stream});
                    },
                    audioLevelUpdated: (audioLevelUpdatedEvent) => {
                        this.props.setAudioLevel(audioLevelUpdatedEvent.audioLevel);
                    },
                    ...this.props.eventHandlers,
                }}
                properties={{
                    frameRate: 30, // 30, 15, 7, 1
                    resolution: '1280x720', // 1280x720(16:9), 640x480(4:3), 320x240(4:3). 
                    ...this.props.properties
                }}
                onError={this.onError}
            />
        </div>
        );
    }
}
export default withAudioLevel(Publisher);