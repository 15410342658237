import React from 'react';
import Countly from 'countly-sdk-web';
import Modal from "../../shared/Modal/Modal";
import MessageInline from '../../shared/MessageInline';
import './CheckIn.scss';
import {countlyAddEvent} from '../../countly';

class FirstTimeCheckIn extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        Countly.track_pageview("firstTimeCheckIn");
    }
    handleAudioVideoPermissions = (event) => {
        countlyAddEvent("clickGiveAccessCameraMic");
        this.props.handleAudioVideoPermissions(event);
    }
    render(){
        const first_name = this.props.first_name;

        return (
            <Modal isModalFullScreen={true} isOpen={true}>
                <div className="box first-time">
                    <h2 className="has-text-centered has-text-weight-bold is-size-5">Hi, {first_name} <br /> Let's set you up for the call.</h2>
                    <p className="has-text-centered is-size-6 mb-5" >
                        To have a successful call, we need access to your camera and microphone
                    </p>
    
                    <figure className="my-6">
                        <img className="is-horizontal-center" src="../../first-time-check-in.png" alt="village medical logo" />
                    </figure>
    
                    {this.props.message &&
                        <MessageInline {...this.props.message} />
                    }
    
                    <div className="has-text-centered">
                        <button className="button is-primary is-fullwidth give-av-permission" onClick={this.handleAudioVideoPermissions} >
                            Give access to camera and mic
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}
export default FirstTimeCheckIn;
