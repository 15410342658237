import React from 'react';
import Publisher from './Publisher';
import VideoAvatar from './VideoAvatar';
import VideoOff from './VideoOff';

class MiniPublisher extends React.Component{
	constructor(props){
		super(props);
		this.state={};
	}
	render(){
		const {publishVideo, name}  = this.props.properties;

		return (
			<div className='mini-video' >
				<div className={!publishVideo ? 'is-hidden' : ''} >
					<Publisher 
						session={this.props.session} 
						audioSource={this.props.audioSource} 
						eventHandlers={this.props.eventHandlers}
						properties={{
							name: "Publisher Name",
							width: '200px',
							height: '125px',
							showControls: false,
							...this.props.properties
						}}
					/>
				</div>
				{!publishVideo && (this.props.useAvatar ? 
					<VideoAvatar text={name} />
				: <VideoOff />)}
			</div>
		);
	}
}
MiniPublisher.defaultProps = {
	useAvatar: true,
};
export default MiniPublisher;